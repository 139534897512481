.loyality-info {
    font-size: 18px;
}
.loyality-info.is-disabled {
    opacity: .6;
}
.loyality-state {
    background-size: 100%;
    background-image: url('/static/img/loyality-state.png');
    width: 70px;
    height: 70px;

    @media screen and (min-width: 1200px) {
        width: 72px;
        height: 72px;
    }
}

.loyality-edelweiss-bronze { background-position: 0 0%; background-size: 100%; }
.loyality-edelweiss-gold { background-position: 0 25%; background-size: 100%; }
.loyality-edelweiss-grau { background-position: 0 50%; background-size: 100%; }
.loyality-edelweiss-silber { background-position: 0 75%; background-size: 100%; }
.loyality-edelweiss { background-position: 0 100%; background-size: 100%; }
.loyality-info__img {
    width: 60px;
    height: auto;

    @media screen and (min-width: 1200px) {
        width: 72px;
    }
}
.loyality-info__hl {
    font-size: 22px;
    line-height: calc(36/30);

    @media screen and (min-width: 768px) {
        font-size: 26px;
        margin-top: 5px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 30px;
        margin-top: 8px;
        margin-bottom: 5px;
    }
}
.hero-img__content__loyality-info {
    margin-top: 30px;
    position: relative;

    @media screen and (max-width: 767px) {
        padding-bottom: 80px;
    }
    
    @media screen and (min-width: 1600px) {
        margin-top: 80px;
    }
}
@media screen and (max-width: 767px) {
    .hero-img__content__loyality-info .slider__arrow {
        right: auto;
        left: 50%;
    }
    .hero-img__content__loyality-info .slider__arrow.slick-prev {
        transform: translateX(-100%);
    }
}
.loyality-slide.is-disabled {
    opacity:0.6;
}
.loyality-poster-label--horizontal {
    width:100%;
    max-width: 565px;
}
.loyality-poster-label--vertical {
    width:100%;
    max-width: 376px;
}