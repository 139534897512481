.modal-close {
    font-size: 20px;
    opacity: 1;
}
.modal-close {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
    cursor: pointer;
    text-shadow: none;
}
.modal-content {
    border: 0;
}
.modal-body__head {
    margin-bottom: 15px;

    @media screen and (min-width: 768px) {
        margin-bottom: 35px;
    }
}
.modal-header {
    border-bottom:1px solid #f0f0f0;
    display: block;
    padding: 15px;
    font-size: 16px;
    line-height: calc(26/16);
}
.modal-header__hl {
    font-size: 30px;
    margin-bottom: 15px;
}
.modal-footer {
    font-size: 14px;
    line-height: calc(16/14);
    padding: 0 40px;
    display: block;
}
.modal-footer__col {
    padding: 20px 0;
}
.modal-footer .col + .col .modal-footer__col:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #fff;
}

/* large modal */
@media screen and (min-width: 768px) {
    .modal-lg{
        width: 100%;
        max-width: 840px;
    }
}
.modal-lg .modal-body {
    padding: 40px 20px;
    font-size: 16px;

    @media screen and (min-width: 768px) {
        padding: 55px;
    }
}
.modal-lg .modal-header {
    padding: 35px 30px 30px;
    margin-bottom: -20px;
}
.modal-lg .modal-close {
    @media screen and (min-width: 768px) {
        top: 30px;
        right: 30px;
    }
}

/* large modal */
@media screen and (min-width: 768px) {
    .modal-xl{
        width: 100%;
        max-width: 1200px;
    }
}
.modal-xl .modal-body {
    padding: 40px 20px;
    font-size: 16px;

    @media screen and (min-width: 768px) {
        padding: 55px;
    }
}
.modal-xl .modal-header {
    padding: 35px 30px 30px;
    margin-bottom: -20px;
}
.modal-xl .modal-close {
    @media screen and (min-width: 768px) {
        top: 30px;
        right: 30px;
    }
}

/* modal background */
.modal-content__background {
    opacity: .3;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
}

/* modal ribbon */
.modal-content__ribbon {
    background: var(--color-primary);
    color: #fff;
    padding: 13px 20px;
    position: relative;
    margin-left: -7px;
    margin-right: -7px;
    margin-bottom: 30px;

    @media screen and (min-width: 768px) {
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 50px;
        font-size: 20px;
    }
}
.modal-content__ribbon:after, .modal-content__ribbon:before {
    content: "";
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
    border-style: solid;
}
.modal-content__ribbon:before {
    left: 0;
    border-width: 0 0 7px 7px;
    border-color: transparent transparent #7a9b05;

    @media screen and (min-width: 768px) {
        border-width: 0 0 10px 10px;
    }
}
.modal-content__ribbon:after {
    right: 0;
    border-width: 7px 0 0 7px;
    border-color: transparent transparent transparent #7a9b05;

    @media screen and (min-width: 768px) {
        border-width: 10px 0 0 10px;
    }
}

.modal--occupancy .modal-body{
    font-size: 18px;
}