.grid-box {
    padding: 25px 15px;

    @media screen and (min-width: 768px) {
        padding: 15px;
    }
    @media screen and (min-width: 1200px) {
        padding: 50px 40px;
    }
    @media screen and (min-width: 1400px) {
        padding: 60px 84px;
    }
}

.grid-box--negative-offset{
    @media screen and (min-width: 768px) {
        margin-top: -110px;
    }
    @media screen and (min-width: 1200px) {
        margin-top: -130px;
    }
    @media screen and (min-width: 1400px) {
        margin-top: -150px;
    }
}

.grid-box__subtitle {
    font-size: 22px;
    line-height: calc(50/30);

    @media screen and (min-width: 768px) {
        font-size: 30px;
        margin-top: 10px;
    }
}
.grid-box__hl {
    font-size: 30px;
    line-height: calc(45/40);

    @media screen and (min-width: 768px) {
        font-size: 40px;
    }
}
.grid-box__hl-lg {
    font-size: 25px;
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: 38px;
        line-height: 1.0625;
    }
    @media screen and (min-width: 1200px) {
        font-size: 50px;
    }
    @media screen and (min-width: 1400px) {
        font-size: 60px;
    }
}
/* collapse */
.grid-box__collapse + .grid-box__collapse {
    margin-top: 20px;
}
.grid-box__collapse {
    border: 0;
}
.grid-box__collapse__header > a {
    display: block;
    font-size: 20px;
    position: relative;
    padding: 14px;
}
.grid-box__collapse__header a:before {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%) scaleY(1);
    border-style: solid;
    border-width: 15px 14px 0 14px;
    border-color: var(--color-grey-light) transparent transparent transparent;
    transition: transform 250ms ease-in-out;
    transform-origin: top center;
}
.grid-box__collapse__header a.collapsed:before {
    transform: translateX(-50%) scaleY(0);
}
.grid-box__collapse__header > a:focus {
    background: var(--color-grey-light);
    border-color: var(--color-grey-light);
}
.grid-box__collapse__body {
    border: 2px solid var(--color-grey-light);
    border-top:0;
    padding: 25px 20px;
}
.grid-box__collapse__body .form-control {
    height: 55px;
    padding: 12px 22px;
    font-size: 20px;
}