.btn {
    border-radius: 0;
    line-height:calc(20/16);
    padding: 8px 20px 11px;
    cursor: pointer;
}
.btn.btn:focus,
.btn:not([disabled]):not(.disabled).active, .btn:not([disabled]):not(.disabled):active{
    box-shadow: none;
}

/* button colors */
.btn-primary,
.btn-primary:focus {
    color: #fff;
}
.btn-secondary {color: #fff !important;}
.btn-white { background: #fff; color: var(--color-default)}
.btn-white:hover,
.btn-white:focus {
    color: var(--color-primary);
}
.btn-grey {background: var(--color-grey-light); color: var(--color-text-default)}
.btn-grey:hover, .btn-grey:focus {background: #e5e5e5;}

.btn-facebook {
    background: #3b5999;
    color: #fff;
}
.btn-google {
    background: #dc4e41;
    color: #fff;
}
.btn-default:focus {
    color: #fff;
}

.btn-default-outline{
    background: transparent;
    border: 1px solid var(--color-default);
}

.btn-no-styling,
.btn.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

/* button sizes */
.btn-lg {
    padding: 10px 20px 13px;
    font-size: 18px;

    @media screen and (min-width: 768px) {
        font-size: 20px;
    }
}
.btn-xl {
    padding: 14px 40px;
    font-size: 20px;
    text-transform: uppercase;
}