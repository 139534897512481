.btn-number-spinner{
    width: 60px;
    height: 60px;
    background-color: #f0f0f0;
    color: var(--color-primary);
    font-size: 25px;
}

.number-spinner__input.form-control{
    width: 60px!important;
    height: 60px;
    background-color: #f0f0f0;
    padding: 5px;
    flex: none;
    text-align: center;
    border: none;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    font-size: 22px;
    font-family: var(--font-bold-family);
}