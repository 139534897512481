.navbar-inline {
    padding: 0;
    height: 60px;
    display: flex;
    align-items: normal;

    @media screen and (min-width: 1200px) {
        height: 75px;
    }

    @media screen and (max-width: 767px) {
        position: fixed;
        bottom: 25px;
        left: 8px;
        right: 8px;
        height: 50px;
        z-index: 1000;
        transition: transform 250ms ease-in-out;
        transform: translateY(100px);
    }
}

@media screen and (max-width: 767px) {
    .navbar-inline.is-scroll-spy {
        transform: translateY(0);
    }
}

@media screen and (min-width: 768px) {
    .navbar-inline__left {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        transform: translateX(-100%);
        transition: transform 250ms ease-in-out;
    }
}
.navbar-inline__left__button {
    @media screen and (min-width: 768px){
        width: 55px;
        height: 60px;
        font-size: 18px;
    }
    @media screen and (min-width: 1200px){
        width: 75px;
        height: 75px;
        font-size: 22px;
    }
}
.navbar-inline__left__logo {
    float: left;

    @media screen and (min-width: 768px){
        height: 71px;
    }
    @media screen and (min-width: 1200px){
        height: 88px;
    }
}
.navbar-inline__left__logo > img {
    max-height: 100%;
    width: auto;
}

.navbar-inline__main {
    float: left;
    height: 50px;
    text-transform: uppercase;
    font-family: var(--font-bold-family);

    @media screen and (min-width: 768px) {
        padding-left: 122px;
        height: 75px;
        font-size: 14px;
        width: 100%;
    }
    @media screen and (min-width: 1200px) {
        padding-left: 148px;
    }
    @media screen and (min-width: 1400px) {
        padding-left: 212px;
        font-size: 16px;
    }
    @media screen and (min-width: 1600px) {
        padding-left: 232px;
    }
}
.navbar-inline__main__nav {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-right: 100px;
    position: relative;
    height: 50px;
    /*overflow: hidden*/

    @media screen and (min-width: 768px) {
        margin: 0;
        height: auto;
        /*overflow-x: auto;
        flex-wrap: nowrap;*/
    }
}
/*
.navbar-inline__main__nav:hover {
    @media screen and (min-width: 768px) {
        overflow-x: auto;
    }
}

.navbar-inline__main__nav::-webkit-scrollbar-thumb {
    @media screen and (min-width: 768px) {
        background-color: var(--color-dark);
        border-radius: 10px;
        border: 3px solid #fff;
    }
}
.navbar-inline__main__nav::-webkit-scrollbar-thumb:hover, .navbar-inline__main__nav::-webkit-scrollbar-thumb:focus {
    @media screen and (min-width: 768px) {
        background-color: var(--color-default);
    }
}
.navbar-inline__main__nav::-webkit-scrollbar-track {
        background: #fff;
}*/

.navbar-inline__main__nav .nav-item {
    position: relative;

    @media screen and (min-width: 768px) {
        display: inline-block;
        padding: 18px 3px;
    }
    @media screen and (min-width: 1200px) {
        padding: 24px 10px 25px;
    }

    @media screen and (max-width: 767px) {
        position: absolute;
        left: 10px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
@media screen and (max-width: 767px) {
    .navbar-inline__main__nav .nav-item {
        opacity: 0;
        transition: opacity 250ms ease-in-out;
        pointer-events: none;
    }
    .navbar-inline__main__nav .nav-item.is-active {
        opacity: 1;
        pointer-events: auto;
    }
}
@media screen and (min-width: 768px) {
    .navbar-inline__main__nav .nav-link:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 5px;
        background: var(--color-primary);
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
    }
    .navbar-inline__main__nav .is-active>.nav-link:after {
        transform: scaleX(1);
    }
}
.navbar-inline__main__nav .nav-link {
    padding: 0 8px;
}
.navbar-inline__main__nav .nav-link:hover {
    color: var(--color-primary);
}

/* title */
.navbar-inline__main__title {
    position: relative;
    padding: 18px 30px 18px 0;
    margin-right: 5px;

    @media screen and (min-width: 1200px) {
        padding: 24px 45px 24px 0;
    }
}
.navbar-inline__main__title:before {
    content: var(--icon-arrow-right);
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 22px;
    opacity: .4;

    @media screen and (min-width: 1200px) {
        font-size: 30px;
    }
}

/* mobile controls */
.navbar-inline__main__controls {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}
.navbar-inline__main__controls > .btn {
    font-size: 12px;
}
.navbar-inline__main__controls > .btn + .btn:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -3px;
    width: 1px;
    background: #787878;
}