/* intro slides */
[class^="demi-infrastructure-"][class$="--intro"] {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;

    @media screen and (min-width: 768px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
[class^="demi-infrastructure-"][class$="--intro"] h2 {
    color: var(--color-primary);
    text-transform: none;
    font-size: 20px;
    line-height: calc(45/35);

    @media screen and (min-width: 768px) {
        font-size: 35px;
    }
}
[class^="demi-infrastructure-"][class$="--intro"] .lead {
    font-size: 18px;

    @media screen and (min-width: 768px) {
        font-size: 20px;
    }
}

/* content-block*/
.demi-infrastructure-overview-content-block:not(.demi-infrastructure-overview-content-block--intro) + .demi-infrastructure-overview-content-block,
.demi-infrastructure-detail-content-block:not(.demi-infrastructure-detail-content-block--intro) + .demi-infrastructure-detail-content-block {
    margin-top: 35px;

    @media screen and (min-width: 768px) {
        margin-top: 65px;
    }
}
.demi-infrastructure-detail-content-block--prices {
    margin-bottom: 35px;

    @media screen and (min-width: 768px) {
        margin-bottom: 65px;
    }
}

/* filters */
.demi-infrastructure-sidebar__box {
    font-size: 18px;
}
.demi-infrastructure-sidebar__box {
    padding:10px 0;
    position: relative;
    border-bottom: 1px solid #fff;
}
.demi-infrastructure-sidebar__collapse-icon {
    padding: 5px;
    font-size: 12px;
    transition: transform 250ms ease-in-out;
}
.collapsed .demi-infrastructure-sidebar__collapse-icon {
    transform: rotate(-45deg);
}
.demi-infrastructure-sidebar__head {
    cursor: pointer;
    font-size: 18px;
}
.demi-infrastructure-sidebar__head:hover {
    color: var(--color-primary);
}
.demi-infrastructure-sidebar__body {
    padding: 10px 0;
}
.demi-infrastructure-sidebar__body .custom-checkbox + .custom-checkbox {
    margin-top: 10px;
}

/* quickfilter */
.quick-filter {
    background: transparent;
    border: none;
    background: var(--color-primary);
    color: #fff;
    cursor: pointer;
    font-size: 14px;
}
.quick-filter:not(:last-child) {
    margin-right: 5px;
}
.quick-filter:hover {
    background: #6e8e00;
}
.quick-filter__icon {
    vertical-align: 0.1em;
    font-size: 8px;
    margin-left: 10px;
}

/* paging */
.demi-infrastructure-paging {
    margin-bottom: 30px;
    font-size: 16px;

    @media screen and (min-width: 768px) {
        font-size: 18px;
    }
}
.row--has-demi-infrastructure-teaser + .demi-infrastructure-paging {
    margin-top: 30px;
}

/* show in map link*/
.demi-intrastructure-show-map {
    font-size: 18px;
    margin-bottom: 20px;
}
.demi-intrastructure-show-map__icon {
    font-size: 30px;
    vertical-align: -0.26em;
    margin-right: 8px;
}


/* slider */
.demi-infrastructure-detail-content-block--gallery .slick-slide figure {
    margin: 0;
}