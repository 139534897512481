@media screen and (max-width: 767px) {
    html.has-overflow-hidden, .has-overflow-hidden body {
        overflow: hidden;
    }
    .navbar-collapse {
        position: absolute;
        height: calc(100vh - 55px);
        border-top: 3px solid var(--color-primary);
        background: #fff;
        color: var(--color-dark);
        left: 0;
        right: 0;
        top: 55px;
        transform: translateX(-100%);
        transition: transform 250ms ease-in-out;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling:touch;
    }
    .is-open .navbar-collapse {
        transform: translateX(0);
    }
    .navbar-main {
        flex-direction: row;
    }
    .navbar-nav {
        display: block;
        width: 100%;
        margin: 0;
    }
    .navbar-nav li {
        border-bottom:1px solid var(--color-grey-light);
    }
    .navbar-nav li a {
        font-size: 20px;
        padding: 10px 15px;
        display: block;
        position: relative;
    }

    .navbar-nav li.is-open > a {
        color: var(--color-primary);
    }

    .navbar-main__dropdown__container li {
        border-top:1px solid #fff;
    }

    .navbar-main__dropdown__list li a {
        font-size: 16px;
        padding: 12px 30px;
        transition: background 250ms ease-in-out, color 250ms ease-in-out;
    }

    .navbar-main__dropdown__list li.is-open > a {
        background: var(--color-primary);
        color: #fff;
    }

    .navbar-main__dropdown__list--subnav li a {
        font-size: 14px;
        padding: 10px 35px;
    }

        /* toggle button */
    .navbar-toggler {
        width: 55px;
        height: 55px;
        text-align: center;
        font-size: 0;
    }
    .navbar-toggler__bar {
        display: inline-block;
        width: 18px;
        height: 3px;
        background: var(--color-default);
        transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
    }
    .navbar-toggler__bar + .navbar-toggler__bar {
        margin-top: 4px;
    }

    .is-open .navbar-toggler__bar:nth-child(2) {
        opacity: 0;
    }
    .is-open .navbar-toggler__bar:nth-child(1) {
        transform: rotate(-45deg) translateX(-5px) translateY(5px);
    }
    .is-open .navbar-toggler__bar:nth-child(3) {
        transform: rotate(45deg) translateX(-5px) translateY(-5px);
    }

    .navbar-nav__toggle {
        position: absolute;
        padding: 20px 15px 20px 35px;
        right: 0;
        top: 0;
        bottom: 0;
        font-size: 12px;
        z-index: 2;
    }
    .navbar-nav__toggle:before {
        display: block;
        transform: rotate(45deg);
        transition: transform 250ms ease-in-out;
    }
    .navbar-nav li.is-open .navbar-nav__toggle:before {
        transform: rotate(0);
    }
    .navbar-main__dropdown li.is-open .subnav-toggle:before {
        transform: rotate(0);
    }

    .navbar-main__dropdown {
        display: none;

    }
    .navbar-nav li.is-open .navbar-main__dropdown {
        display: block;
    }

    /* navbar brand */
    .navbar-brand {
        padding: 0;
        height: 65px;
        margin-bottom: -10px;
    }
    .navbar-brand > img {
        max-height: 100%;
    }

    /* navbar-bottom */
    .navbar-bottom {
        padding: 5px 15px;
    }
    .navbar-bottom .btn {
        margin-top: 20px;
    }

    /* dark nav */
    .navbar-main--dark .navbar-toggler__bar {
        background: #fff;
    }
}