.console {
    position: fixed;
    right: 0;
    bottom:0;
    z-index: 1000;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    background: #333333;
    opacity: 0;
    transform: translateX(100%);
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
}
.console.is-affix {
    transform: translateX(0);
    opacity: 1;
}
@media screen and (min-width: 768px) {
    .modal-open .console {
        right: 17px;
    }
}
.console li + li {
    margin-top: 1px;
}
.console__btn {
    width: 60px;
    height: 60px;
    font-size: 26px;
    padding: 5px;
    position: relative;

    @media screen and (min-width: 1200px){
        width: 80px;
        height: 80px;
        font-size: 35px;
    }
}
.console__btn__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 60px;
    height: auto;
}
.console__btn__text {
    font-size: 14px;
    padding: 10px 0;
}
.console__btn__text__icon {
    font-size: 32px;
}