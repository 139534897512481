.select {
    position: relative;
}
.select>select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select:before {
    font-family: "iconfont";
    content: var(--icon-arrow-down);
    color: var(--color-primary);
    speak: none;
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    font-size: 10px;
    padding: 5px 15px;
    align-items: center;
    display: flex;
    background: #fff;
    pointer-events: none;

    @media screen and(min-width: 768px){
        padding: 18px;
    }
}
.select-grey:before {
    background: var(--color-grey-light);
    color: var(--color-default);
}
.select-bordered:before {
    background: transparent;
}
.select > .control-label {
    font-size: 12px;
    transform: none;
    top: -8px;
    left: 12px;
}
select.form-control:not([size]):not([multiple]) {
    height: 55px;
}
/* small form group */
.form-group-sm > .select > select.form-control {
    height: 47px;
}