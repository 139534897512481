.dropdown-teaser {
    position: relative;
}
.dropdown-teaser:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.dropdown-teaser__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 22px 38px 0;
    z-index: 1;
}
.dropdown-teaser__content:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 5px;
    width: 42px;
    background: #fff;
}
.dropdown-teaser__content__hl {
    font-size: 25px;
    line-height: 1;
    margin-bottom: 20px;
}