.weather-box {
    position: relative;
    padding: calc(30rem / 16);

    @media screen and (min-width: 1400px) {
        padding: 40px;
    }
    @media screen and (min-width: 1600px) {
        padding: 55px 85px;
    }
}
.weather-box--bg-video {
    color: #fff;
    text-shadow: 0 0 3px rgba(0,0,0,.65);
}
.weather-box__hl {
    font-size: 24px;
    line-height:calc(33/30);
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
        font-size: 30px;
        margin-bottom: 35px;
    }
}
.weather-box__icon {
    font-size: 60px;
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
        font-size: 90px;
        margin-bottom: 30px;
    }
}
.weather-box__temperature{
    font-size: 30px;
    line-height:calc(40/35);

    @media screen and (min-width: 768px) {
        font-size: 35px;
    }
}

.weather-box__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}
.weather-box__bg__video {
    position: absolute;
    bottom: 0;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%);

    @media screen and (max-width: 767px) {
        max-width: calc(345rem / 16);
    }
}