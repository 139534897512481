.clearfix{
    clear: both;
}

.form-control--q-chatbot{
    padding: 15px;
    border-radius: 19px;
    border: 1px solid #1e1e1e;
    height: auto;
}

.chatbot button{
    cursor: pointer;
}

.chatbot__bot{
    position: fixed;
    right: 10px;
    bottom: 0;
    z-index: 5000;

    @media screen and (min-width: 768px){
        right: 80px;
    }
}

.chatbot-img{
    @media screen and (max-width: 767px){
       width: 50px;
    }
}

.modal-open .chatbot__bot{
    z-index: 1000;
}

.chatbot__bot__bubble{
    position: absolute;
    background-color: #fff;
    color: #000;
    left: 20px;
    top: 0;
    width: 185px;
    border-radius: 19px;
    padding: 10px;
    transform: translateX(-100%) translateY(-100%);
    font-size: 15px;
    line-height: calc(21/18);

    @media screen and (min-width: 768px){
        font-size: 18px;
        width: 230px;
        padding: 20px;
    }
}

.chatbot__bot__bubble:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border: 22px solid transparent;
    border-top-color: #ffffff;
    border-bottom: 0;
    border-right: 0;
    margin-left: -10px;
    margin-bottom: -22px;
    right: 16px;
}

.chatbot__bot.is-open .chatbot__bot__bubble{
    display: none;
}

.chatbot__bot.is-open{
    @media screen and (min-width: 768px){
        transform: translateX(-370px);
    }
    
    @media screen and (max-width: 767px){
        right: auto;
        left: 5px;
    }
}

.chatbot__bot__bubble .btn{
    border-radius: 10px;
    padding: 4px 15px;
}

.chatbot__bot__bubble-info{
    display: none;
}

.chatbot__bot.is-closed .chatbot__bot__bubble-info{
    display: block;
}

.chatbot__bot.is-closed .chatbot__bot__bubble-start{
    display: none;
}

.chatbot__bot.is-hide .chatbot__bot__bubble{
    display: none;
}

.chatbot__bot__img{
    position: relative;
    
    @media screen and (min-width: 768px){
       height: 140px;
    }
}

.chatbot-img--close{
    display: none;
}

.chatbot__bot.is-open .chatbot-img{
    transform: scaleX(-1);
}

.chatbot__bot__full{
    padding: 0;
    font-size: 12px;
    line-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 5px;
    display: none;
    
    @media screen and (max-width: 767px){
        width: 20px;
        height: 20px;
        font-size: 8px;
        padding: 0;
        border-radius: 6px;
        line-height: 1;
        top: -6px;
        right: 2px;
    }
}

.chatbot__bot.is-hide .chatbot__bot__full{
    display: block;
}

.chatbot__hide{
    text-decoration: underline;
}

.chatbot__close .icon{
    vertical-align: -2px;
    font-size: 14px;
    margin-left: 5px;
}

.chatbot__chat{
    background-color: #fff;
    position: fixed;
    top: 55px;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 4000;
    box-shadow: 0 0 24px 0 rgba(0,0,0,0.15);
    flex-direction: column;
    display: none;

    @media screen and (min-width: 768px){
        width: 520px;
        top: 80px;
    }
}

.chatbot__restart{
    font-size: 14px;
    @media screen and (min-width: 768px){
        font-size: 16px;
    }
}

.chatbot__restart .icon{
    vertical-align: -3px;
    margin-left: 5px;
}

.modal-open .chatbot__chat:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
    z-index: 100;
}

.chatbot__chat.show{
    display: flex;
}

.chatbot__top{
    background-color: #F0F0F0;
    padding: 15px 18px;
    font-size: 16px;
}

.chatbot__main{
    padding: 50px 35px;
    overflow-y: auto;
}

.chatbot__bottom{
    padding: 50px 35px;
}

.chatbot__text{
    border-radius: 19px;
    font-size: 18px;
    padding: 20px 24px;
    margin-bottom: 18px;
}

.chatbot__text--bot{
    background-color: #ededed;
    float: left;
}

.chatbot__text--bot.transparent {
    background-color: transparent;
}

.chatbot__text--user{
    background-color: #1E1E1E;
    color: #fff;
    float: right;
}

.chatbot__query-btn{
    background-color: #448332;
    border: none;
    border-radius: 0;
    padding: 12px 15px;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    cursor: pointer;
    line-height: 1;
    
    @media screen and (max-width: 767px){
       font-size: 12px;
    }
}

.chatbot__query-btn .icon{
    vertical-align: -2px;
    margin-left: 5px;
}

.modal{
    z-index: 5000;
}