.embed-responsive-2by3:before {
    padding-top: calc(3 / 2 * 100%);
}
.embed-responsive-cropper-postcard:before {
    padding-top: calc(10 / 15  * 100%);
}
.embed-responsive-cropper-postcard--vertical:before {
    padding-top: calc(15 / 10  * 100%);
}
.embed-responsive-cropper-poster:before {
    padding-top: calc(30 / 20  * 100%);
}

.embed-responsive-cover{
    height: 100%;
}

.embed-responsive-cover .embed-responsive-item{
    width: auto;
    min-height: 100%;
    left: 50%;
    transform: translateX(-50%);
}