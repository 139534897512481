.login-box__box {
    padding: 20px;

    @media screen and (min-width: 768px) {
        padding: 50px 70px;
    }
}
.login-box__box__block + .login-box__box__block {
    margin-top: 25px;

    @media screen and (min-width: 768px) {
        margin-top: 40px;
    }
}

.login-box__box__block__hl {
    font-size: 16px;
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
        font-size: 25px;
        margin-bottom: 20px;
    }
}
/* box tabs */
.login-box__tabs {
    margin-bottom: 0;
}
.login-box__tabs .nav-link {
    padding: 12px 5px;

    @media screen and (min-width: 768px) {
        font-size: 25px;
        padding: 25px;
    }
}
.login-box__tabs .nav-link.active:after {
    content: none;
}
.login-box__tabs .nav-link {
    color: #a2a2a2;
    border: 1px solid #fff;
}
.login-box__tabs .nav-link:hover,
.login-box__tabs .nav-link:focus {
    color: var(--color-primary);
    border: 1px solid #fff;
}
.login-box__tabs .nav-item.show .nav-link, .login-box__tabs .nav-link.active {
    border: 1px solid #fff;
    background: #fff;
    color: var(--color-default);
}

/* small login box */
.login-box--sm .login-box__box {
    padding: 20px;
}
.login-box--sm .login-box__box__block + .login-box__box__block {
    margin-top: 25px;
}