:root {
    --cookie-font-size: 1.5rem;
    --cookie-switch-width: calc(60rem/16);
    --cookie-switch-height: calc(var(--cookie-switch-width)/60*34);
    --cookie-switch-toggle-size: calc(var(--cookie-switch-height)/34*26);
}
.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #212529;
    color: #ffffff;
    padding: .75rem 1rem;
    z-index: 11111 !important;
}
.cookie-bar.cookie-bar--top{
    bottom: unset;
    top: 0;
}
.cookie-bar::before{
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    z-index: -1;
}

@media screen and (min-width: 768px) {
    .cookie-bar {
        display: flex;
        align-items: center;
    }
}

.cookie-bar__left{
    width: 100%;
    font-size: calc(14rem / 16);

    @media scren and (min-width: 768px) {
        display: flex;
    }
}

.cookie-bar__text,
.cookie-bar__detail{
    display: inline-block;
}

@media screen and (min-width: 768px){
    .cookie-bar__text{
        margin-right: calc(15rem/16);
    }
}
.cookie-bar__detail-link{
    text-decoration: underline;
    font-size: calc(16rem/16);
}

.cookie-bar__detail-link +.cookie-bar__detail-link{
    margin-left: calc(15rem/16);
}

.cookie-bar__detail-link:hover{
    text-decoration: none;
}


.cookie-bar__detail-btn-no-style{
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    color: inherit;
    cursor: pointer;
}

.cookie-bar__buttons {
    text-align: center;

    @media screen and (min-width: 768px) {
        flex: none;
        margin-left: 1rem;
        text-align: right;
    }
}

.cookie-bar__btn {
    margin-left: .75rem;

    @media screen and (max-width: 767px) {
        margin-top: .75rem;
    }
}

/* modal */
.cookie-modal {
    z-index: 11112 !important;
}
.cookie-modal .modal-header .close{
    padding: calc(2rem/16) calc(15rem/16) calc(8rem/16);
    margin: 0;
}

.cookie-modal .modal-header .close:focus{
    outline: none;
}

.cookie-modal .modal-header .close>span{
    display: inline-block;
    line-height: 1;
}

.cookie-modal__item + .cookie-modal__item {
    margin-top: .75rem;
}
.cookie-modal__item--all {
    margin-bottom: 1rem;
}
.cookie-modal__label {
    font-size: var(--cookie-font-size);
}
.cookie-modal__link {
    display: inline-block;
    margin-left: .5rem;
    text-decoration: underline;
}

/* switch */
.cookie-modal__switch {
    position: relative;
    display: inline-block;
    width: var(--cookie-switch-width);
    height: var(--cookie-switch-height);
    vertical-align: middle;
    margin-right: .5rem;
}
.cookie-modal__switch-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: var(--cookie-switch-height);
}
.cookie-modal__switch-toggle:before {
    position: absolute;
    content: "";
    height: var(--cookie-switch-toggle-size);
    width: var(--cookie-switch-toggle-size);
    left: calc((var(--cookie-switch-height) - var(--cookie-switch-toggle-size)) / 2);
    bottom: calc((var(--cookie-switch-height) - var(--cookie-switch-toggle-size)) / 2);
    background-color: white;
    transition: .4s;
    border-radius: var(--cookie-switch-height);
}
input:checked + .cookie-modal__switch-toggle {
    background-color: var(--color-primary);
}
input:focus + .cookie-modal__switch-toggle {
    box-shadow: 0 0 2px var(--color-primary);
}
input:checked + .cookie-modal__switch-toggle:before {
    transform: translateX(var(--cookie-switch-toggle-size));
}
.cookie-modal__switch--disabled {
    opacity: .65;
}
.cookie-modal__group {
    margin-bottom: .75rem;
}
.cookie-modal__group-header {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cookie-modal__group-body {
    overflow: hidden;
    font-size: var(--cookie-font-size);
    padding: calc(14rem/16) calc(18rem/16);
}
.cookie-modal__group-toggle-icon {
    text-indent: 0;
    font-size: calc(18rem/16);
    display: inline-block;
    font-family: 'iconfont';
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: rotate(180deg);
    transition: transform 120ms ease-out;
}
.cookie-modal__group-toggle-icon:before {
    content: var(--icon-cookie-arrow);
}
.cookie-modal__group-toggle.collapsed .cookie-modal__group-toggle-icon {
    transform: rotate(0);
}
input[disabled] + .cookie-modal__switch-toggle {
    cursor: default;
}
.cookie-modal__switch-toggle--is-disabled {
    opacity: 0.7;
}
.cookie-modal__group-body .cookie-modal__label {
    font-size: 0.9em;
}
.cookie-modal__switch--group {
    width: calc(var(--cookie-switch-width) * 0.9);
    height: calc(var(--cookie-switch-height) * 0.9);
}
.cookie-modal__switch--group .cookie-modal__switch-toggle:before {
    width: calc(var(--cookie-switch-toggle-size) * 0.9);
    height: calc(var(--cookie-switch-toggle-size) * 0.9);
}
.cookie-modal__switch--group input:checked + .cookie-modal__switch-toggle:before {
    transform: translateX(calc(var(--cookie-switch-toggle-size) * 0.9));
}
[hidden]{
    display: none!important;
}
.cookie-modal__title {
    font-size: 24px;
    text-transform: none;
    font-family: var(--font-bold-family);
    font-weight: 400;

    @media screen and (min-width: 768px) {
        font-size: 30px;
    }
}
.cookie-modal .modal-footer {
    padding: 15px;
    text-align: right;
}
.cookie-modal .close {
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 1;
    cursor: pointer;
    text-shadow: none;
    font-size: 40px;
}