.info-box {
    margin: 2px 0;
    padding: 25px 10px 10px;
    line-height:1;
}
.info-box__text-lg {
    font-size: 35px;
}
.info-box__link {
    margin-top: 8px;
}
.info-box__link__icon {
    font-size: 10px;
    margin-right: 2px;
}