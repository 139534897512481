.collapse-item {
    background: #fff;
    border: 0;
}
.collapse-item:nth-child(even) {
    background: var(--color-grey-light);
}
.collapse-item__head__title {
    display: block;
    font-size: 18px;
    padding: 11px 17px;
    border: 3px solid transparent;
}
a.collapse-item__head__title {
    border: 3px solid #fff;
}
.collapse-item__head .collapsed.collapse-item__head__title {
    border: 3px solid rgba(255, 255, 255, 0);
}
.collapse-item__body {
    padding: 15px 20px;
    border: 3px solid #fff;
    border-top: 0;
}
.collapse-item__body__data {
    font-size: 16px;
    line-height: calc(30/16);
}

/* dark collapse items */
.collapse-item.collapse-item--dark {
    background: transparent;
}
.collapse-item--dark > .collapse-item__head > a {
    background: var(--color-dark);
    color: #fff;
    border: 3px solid transparent;
}

.collapse-item--description {
    padding: 0 17px 10px;
    border-bottom: 1px solid var(--color-default);
}