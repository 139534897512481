/* basic form controls style */
.form-control {
    height: 55px;
    border-color: #fff;
    border-radius: 0;
    font-size: 16px;
    line-height: 1.25;
    padding: 23px 20px 7px;
    font-family:var(--font-regular-family);
    font-weight: 400;
    color: var(--color-default);

    @media screen and (min-width: 768px) {
        font-size: 20px;
    }
}
.form-control[readonly] {
    background: #fff;
}
.form-control:focus {
    border-color: var(--color-primary);
    box-shadow: 0 0 0 2px rgba(140,180,0,.4);
}
.form-control-no-label {
    padding: 13px 20px 12px;
}
.required-symbol {
    color: var(--color-danger);
    font-size: 22px;
    line-height: 1;
    vertical-align: top;
}

/* bordered form controls */
.form-control-bordered {
    box-shadow: none;
    background: transparent;
    border: 1px solid var(--color-dark);
}
.form-control-bordered:focus {
    background: transparent;
    color: var(--color-dark);
    border: 1px solid var(--color-dark);
}
.form-control-bordered[readonly] {
    background: transparent;
}

/* white bordered form controls */
.form-control-bordered-white {
    box-shadow: none;
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
}
.form-control-bordered-white:focus {
    background: transparent;
    color: #fff;
    border: 2px solid #fff;
}
.form-control-bordered-white[readonly] {
    background: transparent;
}
.form-control-bordered-white.has-value {
    background: #fff;
    color: var(--color-dark);
}
.form-control-bordered-white::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #fff;
}
.form-control-bordered-white::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #fff;
    opacity:  1;
}
.form-control-bordered-white:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
}

/* form groups */
.form-group {
    position: relative;
    margin-bottom: 20px;

    @media screen and (min-width: 786px) {
        margin-bottom: 25px;
    }
}
.form-group-sm .form-control {
    padding-top: 15px;
    padding-bottom: 5px;
    height: 47px;
}
.form-group-auto {
    display: inline-block;
}

/* filter */
.filter-block {
    font-size: 18px;
}
.filter-block__bottom {
    margin-top: 30px;
}
.filter-info {
    font-size: 16px;
    padding-bottom: 20px;
    margin-top: 20px;

    @media screen and (min-width: 768px) {
        font-size: 18px;
        margin-bottom: 30px;
        margin-top: 30px;
    }
}

/* parsley errors */
.parsley-errors-list {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: right;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    font-size: 12px;
    color: var(--color-danger);
}
.custom-radio .parsley-errors-list,
.custom-checkbox .parsley-errors-list {
    text-align: left;
}
.has-error .form-control,
.has-error .form-control:focus {
    border-color: var(--color-danger);
    box-shadow: none;
}

.has-success .form-control,
.has-success .form-control:focus {
    border-color: var(--color-success);
    box-shadow: none;
}
.form-errors .parsley-errors-list {
    position: relative;
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(184, 10, 0, 0.6);
}

/* form blocks */
.form-block {
    margin-bottom: 25px;

    @media screen and (min-width: 768px) {
        margin-bottom: 50px;
    }
}
.form-block__hl {
    font-size: 20px;
    line-height:1.2;
    text-transform: none;

    @media screen and (min-width: 768px) {
        font-size: 25px;
    }
}

/* legend */
legend {
    font-size: 18px;
    font-family:var(--font-bold-family);
    font-weight: 400;
    margin-bottom: 1rem;
}

/* form sections */
.form-section {
    position: relative;
    padding-top: 20px;
}

textarea.form-control{
    height: auto;
}

.is-disabled .form-group {
    opacity: .55;
}