.range-slider {
    height: 4px;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    background: #fff;
    padding: 0 12px;
}
.range-slider .noUi-handle {
    width: 25px;
    height: 25px;
    background: var(--color-dark);
    border-radius: 100%;
    box-shadow: none;
    border: 0;
    top: -10px;
    left: -12px;
    cursor: pointer;
}
.range-slider .noUi-connect {
    background: var(--color-primary);
    box-shadow: none;
}
.range-slider .noUi-handle:before {
    background: transparent;
    height: auto;
    width: auto;
    content: var(--icon-arrow-left);
    font-family:iconfont;
    font-size: 11px;
    line-height: 1;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: #fff;
}
.range-slider .noUi-handle-lower:before {
    transform: translateY(-50%) translateX(-50%) scaleX(-1);
}
.range-slider .noUi-handle:after {
    content: none;
}
.range-slider__values {
    padding-top: 15px;
    font-size: 16px;
}
.range-slider__values__input {
    background: transparent;
    border: 0;
    height: 25px;
    width: 100%;
    cursor: default;
    pointer-events: none;
    line-height:1;
}
