.slider__arrow {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    font-size: 16px;
    z-index: 1;

    @media screen and (min-width: 768px) {
        width: 80px;
        height: 80px;
        font-size: 22px;
    }
}
.slider__arrow.slick-prev {
    right: 50px;

    @media screen and (min-width: 768px) {
        right: 80px;
    }
}
.slider__arrow.slick-prev .icon-btn__icon {
    transform: rotate(180deg) translateY(50%) translateX(50%);
}
.slick-dots {
    text-align: center;
    list-style: none;
    padding: 20px 0 0;
    margin: 0;

    @media screen and (min-width: 768px) {
        padding: 40px 0 0;
    }
}
.slick-dots > li {
    display: inline-block;
    float: none;
}
.slick-dots button {
    border: 0;
    background: #fff;
    text-indent: -9999em;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    opacity: .5;
    margin: 0 10px;
    transition: opacity 250ms ease-in-out;
    padding: 0;
}
.slick-dots .slick-active button,
.slick-dots button:hover,
.slick-dots button:focus {
    opacity: 1;
}

/* slider with thumbs */
.thumb-slider {
    position: relative;
}

@media screen and (max-width: 767px) {
    .thumb-slider__main {
        padding-bottom: 50px;
    }
}
.thumb-slider__main__item__text {
    font-size: 16px;
    padding: 10px;

    @media screen and (min-width: 768px) {
        width: 35%;
    }

    @media screen and (min-width: 1200px) {
        padding: 10px 40px 10px 20px;
    }

    @media screen and (min-width: 1400px) {
        font-size: 18px;
    }
}
.thumb-slider__thumbs {
    position: absolute;
    bottom: 0;
    left: 33.2%;
    right: -1px;
}
.thumb-slider__thumbs__item {
    transition: transform 250ms ease-in-out;
}
.slick-current .thumb-slider__thumbs__item {
    transform: scale(.86) translateX(10px);
}
/* video preview */
.thumb-slider__thumbs__item--video {
    position: relative;
}
.thumb-slider__thumbs__item--video:before {
    content: var(--icon-video);
    font-family: iconfont;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: var(--color-default);
    z-index: 1;
    line-height: 1;
    padding: 9px;
}
.thumb-slider__thumbs .slider__arrow {
    bottom: 100%;
}
.thumb-slider__thumbs .slick-track {
    min-width: 100%;
    text-align: right;
    line-height: 0;
}

.thumb-slider__thumbs .slick-slide {
    float: none;
}
.thumb-slider__thumbs .slick-initialized .slick-slide {
    display: inline-block;
}

/* teaser slider centered arrows */
.teaser-slider {
    position: relative;

    @media screen and (max-width: 767px) {
        padding: 0 30px;
    }
}
.slider--arrows-center .slider__arrow,
.teaser-slider .slider__arrow {
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: 0;
    width: 30px;
    height: 30px;
    padding: 0;
}
.teaser-slider--arows-top .slider__arrow {
    top: 30%;
}
.teaser-slider .slider__arrow.slick-prev {
    left: -10px;

    @media screen and (min-width: 768px) {
        left: -35px;
    }

    @media screen and (min-width: 1660px) {
        left: -60px;
    }
}
.teaser-slider .slider__arrow.slick-next {
    right: -10px;

    @media screen and (min-width: 768px) {
        right: -35px;
    }

    @media screen and (min-width: 1660px) {
        right: -60px;
    }
}

.slider--arrows-center .slider__arrow {
    color: currentColor;
}
.slider--arrows-center .slider__arrow.slick-prev {
    left: 0;

    @media screen and (min-width: 768px) {
        left: 20px;
    }
}
.slider--arrows-center .slider__arrow .icon:before {
    content: var(--icon-arrow-left);
}
.slider--arrows-center .slider__arrow.slick-next {
    right: 0;

    @media screen and (min-width: 768px) {
        right: 20px;
    }
}
.slider--arrows-center .slider__arrow.slick-prev .icon-btn__icon {
    transform: rotate(0) translateY(-50%) translateX(-50%);
}
.slider--arrows-center .slider__arrow.slick-next .icon-btn__icon {
    transform: rotate(180deg) translateY(50%) translateX(50%);
}

.slider--dots--bottom {
    line-height: 1;
}
.slider--dots--bottom .slick-dots {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
}
.slider--dots--bottom .slick-dots button {
    opacity: .8;
}
.slider--dots--bottom .slick-dots .slick-active button,
.slider--dots--bottom .slick-dots button:hover,
.slider--dots--bottom .slick-dots button:focus {
    opacity: 1;
    background: var(--color-primary);
}

/* same height slider */
.slider--same-height .slick-track {
    display: flex;
}
.slider--same-height .slider-item {
    min-height: 100%;
    height: auto;
}

.slider--arrows-center .img-slide {
    padding-left: 20px;
    padding-right: 20px;
}
.slider--dots--bottom .img-slide {
    padding-bottom: 50px;

    @media screen and (min-width: 768px) {
        padding-bottom: 70px;
    }
}