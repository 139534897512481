.share-list ul {
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}
.share-list li {
    display: inline-block;
}
.share-list li:not(:last-child) {
    margin-right: 10px;
}
.share-list li a {
    height: 50px;
    width: 50px;
    display: block;
    position: relative;
    color: #fff;
}

.share-list .facebook > a {
    background: #3b5998;
}
.share-list .facebook > a:hover {
    background: #4273c8;
}
.share-list .googleplus > a {
    background: #d34836;
}
.share-list .googleplus > a:hover {
    background: #f75b44;
}
.share-list .twitter > a {
    background: #55acee;
}
.share-list .twitter > a:hover {
    background: #32bbf5;
}
.share-list .pinterest > a {
    background: #E8001E;
}
.share-list .pinterest > a:hover {
    background: #E8001E;
}

.share-list .shariff-button a:after {
    content: '';
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-family: iconfont;
}

.share-list .facebook a:after { content: var(--icon-facebook); }
.share-list .googleplus a:after { content: var(--icon-googleplus); }
.share-list .twitter a:after { content: var(--icon-twitter); }
.share-list .pinterest a:after { content: var(--icon-pinterest); }