.embed-responsive-item.video-js {
  width: 100% !important;
  height: 100% !important;
}

.video-js{
  color: #fff;
}

.vjs-default-skin .vjs-control {
  background: transparent;
  border: 0;
  color: #fff;
}
.vjs-default-skin .vjs-big-play-button{
  border-radius: 50%;
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #fff;
  color: var(--color-primary);
  border-color: transparent;
  line-height: 1.6;

  @media screen and (min-width: 768px){
    width: 130px;
    height: 130px;
  }
}

.vjs-default-skin .vjs-big-play-button:before{
  content: var(--icon-play);
  font-family: iconfont;
  text-shadow: none;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 22px;
  margin-left: 2px;

  @media screen and (min-width: 768px){
    font-size: 55px;
    margin-left: 12px;
  }
}
.vjs-big-play-button .vjs-icon-placeholder {
  display: none;
}

.vjs-default-skin .vjs-big-play-button:focus,
.vjs-default-skin:hover .vjs-big-play-button{
  background-color: #fff;
  border-color: transparent;
}

.vjs-has-started .vjs-poster{
  display: none;
}

@media screen and (max-width: 767px){
  .vjs-default-skin.vjs-using-native-controls .vjs-big-play-button,
  .video-js.vjs-using-native-controls .vjs-poster{
    display: block;
  }

  .vjs-default-skin.vjs-has-started .vjs-big-play-button{
    display: none;
  }
}
.vjs-audio-button {
  display: none;
}