.control-box input[type="radio"],
.control-box input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.control-box-list {
    font-size: 0;
}
.control-box-list li {
    display: inline-block;
}
.control-box-list li + li .control-box {
    border-left-width: 0;
}
.control-box {
    font-size: 16px;
    line-height: calc(20/16);
    padding: 10px;
    border: 2px solid #fff;
    cursor: pointer;
    position: relative;

    @media screen and (min-width: 768px) {
        padding: 8px 18px;
        min-width: 100px;
    }
}
.control-box__box {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
}
.control-box__text {
    z-index: 1;
    position: relative;
    transition: color 250ms ease-in-out;
}
.control-box__text__icon {
    font-size: 18px;
    margin-top: 5px;

    @media screen and (min-width: 768px) {
        font-size: 20px;
    }
}
.control-box:hover .control-box__box,
.control-box input:focus ~ .control-box__box,
.control-box input:checked ~ .control-box__box {
    opacity: 1;
}
.control-box:hover,
.control-box input:checked ~ .control-box__text,
.control-box input:focus ~ .control-box__text,
.control-box input[type="checkbox"]:checked ~ .control-box__state {
    color: var(--color-dark);
}

.control-box.control-box--checkbox {
    padding: 9px 18px 8px;
}
.control-box__state {
    font-size: 11px;
    margin-left: 10px;
    transform: rotate(-45deg);
    transition: transform 200ms ease-in-out, color 250ms ease-in-out;
}
.control-box input[type="checkbox"]:checked ~ .control-box__state {
    transform: rotate(0);
}

/* white bg */
.control-box--white {
    border: 0;
}
.control-box--white .control-box__box  {
    opacity: 1;
    transition: background 250ms ease-in-out;
}
.control-box--white:hover .control-box__box,
.control-box--white input[type="checkbox"]:checked ~ .control-box__box {
    background: var(--color-primary);
}


.control-box--white input:focus ~ .control-box__text,
.control-box--white input:focus ~ .control-box__state {
    color: var(--color-primary);
}

.control-box--white:hover .control-box__text,
.control-box--white:hover .control-box__state,
.control-box--white:hover input:focus ~ .control-box__text,
.control-box--white:hover input:focus ~ .control-box__state,
.control-box--white input[type="checkbox"]:checked ~ .control-box__text,
.control-box--white input[type="checkbox"]:checked ~ .control-box__state {
    color: #fff;
}

/* large text */
.control-box--lg {
    font-size: 18px;
}

/* dark */
/*
.control-box--dark {
    border: 1px solid var(--color-dark);
    transition: background 250ms ease-in-out;
}
.control-box--dark .control-box__box {
    background: transparent;
}
.control-box--dark:hover {
    background: var(--color-dark);
}
.control-box--dark:hover .control-box__text,
.control-box--dark:hover .control-box__state,
.control-box--dark:hover input:checked ~ .control-box__text,
.control-box--dark:hover input:focus ~ .control-box__text,
.control-box--dark:hover input[type="checkbox"]:checked ~ .control-box__state {
    color: #fff;
}
*/
