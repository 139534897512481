.article-teaser {
    font-size: 16px;
    line-height: calc(26/18);

    @media screen and (min-width: 768px) {
        font-size: 18px;
    }
}
.article-teaser__head {
    margin-bottom: 15px;
}
.article-teaser__hl {
    font-size: 20px;
    line-height: calc(30/25);
    text-transform: none;
    margin-bottom: 5px;

    @media screen and (min-width: 768px) {
        font-size: 25px;
        margin-bottom: 8px;
    }
}
.article-teaser__category {
    color: #7b7b7b;
    margin-bottom: 6px;

    @media screen and (min-width: 768px) {
        margin-bottom: 14px;
    }
}
.article-teaser__category li {
    position: relative;
}
.article-teaser__category li + li {
    padding-left: 4px;
}
.article-teaser__category li + li:before {
    content: '·';
    position: absolute;
    left: -5px;
    top: 0;
    font-size: 18px;
}