.author {
    margin-top: 15px;
    padding-bottom: 5px;
    position: relative;

    @media screen and (min-width: 768px) {
        margin-top: -130px;
        padding-bottom: 25px;
    }
}
.author__img {
    margin-bottom: 8px;

    @media screen and (min-width: 768px) {
        margin-bottom: 12px;
    }
}
.author__title {
    font-size: 30px;
    line-height:1;

    @media screen and (max-width: 767px) {
        font-size: 20px;
    }
}
.author__quotation{
    margin-top: 18px;
}