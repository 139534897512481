.interactive-map {
    position: relative;
    overflow: hidden;
}
.interactive-map__overlay {
    position: fixed;
    top: 0;
    bottom:0;
    left: 0;
    z-index: 1005;
    width: 100%;

    @media screen and (min-width: 768px) {
        z-index: 1;
        position: absolute;
        width: 260px;
    }

    @media screen and (max-width: 767px) {
        overflow: hidden;
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;
    }
}

.is-open.interactive-map__overlay {
    transform: translateX(0);
}

.interactive-map__overlay__top {
    height: 60px;
    padding: 18px 15px;
    box-shadow: 0 2px 3px rgba(0,0,0,.1);
    position: relative;
    z-index: 1;
    display: block;
}

/* filter */
.overlay-filter {
    height: 100%;
    background: var(--color-primary);

    @media screen and (min-width: 768px) {
        width: 100%;
        float: left;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }

    @media screen and (max-width: 767px) {
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling:touch;
    }
}
.overlay-filter li > a {
    color: #fff;
    display: block;
    padding: 16px 45px 14px 60px;
    font-size: 18px;
    position: relative;

    @media screen and (min-width: 768px) {
        padding: 5px 45px 5px 62px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        font-size: 16px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 18px;
    }
}
.overlay-filter li + li a {
    border-top:1px solid #fff;
}
.overlay-filter li > a:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    border-style: solid;
    border-width: 20px 0 20px 15px;
    border-color: transparent transparent transparent var(--color-primary);
    transform: translateY(-50%) translateX(0);
    transition: transform 200ms ease-in-out;
}
.overlay-filter li.is-open > a:after {
    transform: translateY(-50%) translateX(100%);
}
.overlay-filter .toggle-filter {
    position: absolute;
    right: 22px;
    font-size: 12px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 1200px) {
        font-size: 15px;
    }
}
.overlay-filter li.is-open .toggle-filter {
    transform: translateY(-50%) rotate(0);
}
.overlay-filter__icon {
    font-size: 28px;
    position: absolute;
    left: 20px;
    line-height: 1;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (min-width: 1200px) {
        font-size: 33px;
    }
}
.overlay-filter__icon .icon-sportshoe {
    font-size: 18px;

    @media screen and (min-width: 1200px) {
        font-size: 23px;
    }
}
.overlay-filter__icon__count {
    position: absolute;
    top: 50%;
    right: -11px;
    width: 23px;
    height: 23px;
    border-radius: 100%;
    font-size: 10px;
    padding: 7px 0;
    transition: transform 200ms ease-in-out;
    transform: scale(0);
    transform-origin: center;

    @media screen and (min-width: 1200px){
        width: 26px;
        height: 26px;
        padding: 9px 0;
    }
}
.is-active .overlay-filter__icon__count {
    transform: scale(1);
}
.overlay-filter__detail {
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding:15px;
    font-size: 16px;
    display: none;

    @media screen and (min-width: 768px) {
        display: block;
        position: absolute;
        z-index: -1;
        left: 260px;
        width: 640px;
        transform: translateX(-110%);
        transition: transform 200ms ease-in-out;
        padding:50px 55px 15px;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling:touch;
    }
}
.is-open > .overlay-filter__detail {
    display: block;

    @media screen and (min-width: 768px) {
        transform: translateX(0);
    }
}
.overlay-filter__detail__top {
    margin-bottom: 15px;
    @media screen and (min-width: 768px) {
        margin-bottom: 25px;
    }
}
.overlay-filter__detail li + li .custom-checkbox {
    margin-top: 22px;
}

/*fullscreen*/
.interactive-map__fullscreen {
    position: absolute;
    right: 0;
    top: 0;
    width: 55px;
    height: 55px;
    font-size: 20px;

    @media screen and (min-width: 1200px) {
        font-size: 30px;
        width: 80px;
        height: 80px;
    }
}
.is-fullscreen .interactive-map__fullscreen .icon:before {
    content: var(--icon-fullscreen-close);
}
.interactive-map.is-fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}
.is-fullscreen .interactive-map__map {
    height: 100%;
}


.interactive-map__map .is-loading:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}