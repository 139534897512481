.weather-item__hl {
    font-size: 20px;
    line-height:calc(33/30);

    @media screen and (min-width: 768px) {
        font-size: 26px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 30px;
    }
}
.weather-item__icon {
    font-size: 52px;
    margin-bottom: 12px;
    margin-top: 8px;

    @media screen and (min-width: 768px) {
        font-size: 68px;
        margin-bottom: 20px;
        margin-top: 18px;
    }
}
.weather-item__temperature{
    font-size: 28px;
    line-height:calc(40/35);
    position: relative;

    @media screen and (min-width: 768px) {
        font-size: 35px;
    }
}
.weather-item__temperature + .weather-item__temperature {
    padding-left: 15px;
    margin-left: 10px;

    @media screen and (min-width: 768px) {
        margin-left: 15px;
        padding-left: 20px;
    }
}
.weather-item__temperature + .weather-item__temperature:before {
    content: '';
    position: absolute;
    left: -1px;
    top: 6px;
    bottom: 8px;
    width: 2px;
    background: rgba(255, 255, 255, 0.5);
}

/* collapsible weather item */
.weather-item--collapse {
    transition: background 200ms ease-in-out;
    padding: 15px 0 15px;
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;

    @media screen and (min-width: 768px) {
        padding: 25px 0 65px;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
}
.weather-item--collapse.active {
    background-color: rgba(0,0,0,.5);
}
.weather-item__btn {
    transition: opacity 200ms ease-in-out;
}
.weather-item--collapse.active .weather-item__btn {
    opacity: 0;
}
.weather-item--collapse + .weather-item--collapse:before {
    content: '';
    position: absolute;
    left: 0;
    top: 60px;
    width: 1px;
    height: 140px;
    background: #fff;
    transition: opacity 200ms ease-in-out;
    opacity: 1;
}
.weather-item--collapse.active:before {
    opacity: 0;
}
.weather-item--collapse.active + .weather-item--collapse:before {
    opacity: 0;
}
@media screen and (max-width: 767px){
    .weather-item--collapse:nth-child(odd):before {
        content: none;
    }
}

@media screen and (min-width: 768px) {
    .weather-item--collapse:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        border-style: solid;
        border-width: 25px 31px 0;
        border-color: var(--color-dark) transparent transparent transparent;
        z-index: 1;
        transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
        transform-origin: top center;
        opacity: 0;
        transform: translateX(-50%) translateY(100%) scale(0);
    }
    .weather-item--collapse.active:after {
        opacity: 1;
        transform: translateX(-50%) translateY(100%) scale(1);
    }
}