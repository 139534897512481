.offer-badge {
    padding: 7px 20px;
    font-size: 16px;
    display: inline-block;

    @media screen and (min-width: 768px) {
        display: block;
        padding: 15px;
        min-width: 140px;
        max-width: 180px;
    }
    @media screen and (min-width: 1400px) {
        padding: 15px 25px;
        font-size: 20px;
    }
}
.offer-badge__count {
    @media screen and (min-width: 768px) {
        font-size: 56px;
        line-height: .9;
        display: block;
        font-family:var(--font-bold-family);
        font-weight: 400;
    }
    @media screen and (min-width: 1400px) {
        font-size: 80px;
    }
}
.offer-badge__price {
    text-transform: lowercase;
    font-size: 1rem;

    @media screen and (min-width: 768px) {
        display: block;
        text-transform: none;
        margin: 8px -15px -15px;
        padding: 3px;
    }
    @media screen and (min-width: 1400px) {
        margin: 10px -25px -15px;
        padding: 5px 5px 9px;
    }
}

.offer-badge--absolute {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    z-index: 1;

    @media screen and (min-width: 768px) {
        right: auto;
        top: 20px;
        left: 20px;
    }
}
/* small */
.offer-badge.offer-badge--sm {
    font-size: 14px;

    @media screen and (min-width: 768px) {
        font-size: 14px;
        display: block;
        padding: 10px;
        min-width: 125px;
        max-width: 130px;
    }
}
.offer-badge--sm .offer-badge__count {
    @media screen and (min-width: 768px) {
        font-size: 58px;
    }
}
.offer-badge--sm .offer-badge__price {
    font-size: 14px;

    @media screen and (min-width: 768px) {
        margin: 10px -10px -15px;
        padding: 4px 5px;
        font-size: 12px;
    }
}

.offer-badge:hover {
    color: #fff;
}