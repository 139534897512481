.icon-btn {
    position: relative;
    width: 50px;
    height: 50px;
    padding: 5px;
}
.icon-btn__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.icon-btn__icon.icon-rotate-180 {
    transform: translateX(-50%) translateY(-50%) rotate(180deg);
}

/* btn with text and icon */
.btn__icon {
    vertical-align: -0.1em;
    position: relative;
    left: -5px;

    @media screen and (min-width: 768px) {
        left: -10px;
    }
}
.btn__icon--right {
    vertical-align: -3px;
    position: relative;
    right: -6px;
}
.btn__icon--sm {
    font-size: 10px;
    vertical-align: 0;
}

/* btn-xl with icon */
.btn-xl .btn__icon {
    margin-right: 5px;
    font-size: 22px;
}