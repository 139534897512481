.custom-radio input[type="radio"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 7px;
    vertical-align: middle;
    border: 1px solid #c4c6c7;
    margin-top: -2px;
    background: #ffffff;
    border-radius: 50%;
}
.custom-radio__box:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: var(--color-primary);
    opacity: 0;
}
.custom-radio input[type="radio"]:checked + .custom-radio__box:before {
    opacity: 1;
}
.custom-radio input[type="radio"]:focus ~ .custom-radio__box {
    border-color: var(--color-primary);
}
.custom-radio label {
    padding-left: 0;
    font-size: 16px;
    cursor: pointer;
    margin: 0;
}
/*.has-error .custom-radio .custom-radio__box {
    border-color: var(--color-danger);
}*/

/* large radio button */
.custom-radio--lg label {
    font-size: 18px;
}
.custom-radio--lg .custom-radio__box {
    width: 22px;
    height: 22px;
    margin-top: -3px;
}
.custom-radio--lg .custom-radio__box:before {
    width: 12px;
    height: 12px;
}

/* highlight colors radio */
.custom-radio--highlight {
    color: var(--color-grey);
}
.custom-radio--highlight .custom-radio__box {
    border-color: var(--color-grey);
}
.custom-radio--highlight input[type="radio"]:checked ~ .custom-radio__box {
    border-color: var(--color-dark);
}
.custom-radio--highlight input[type="radio"]:checked ~ .custom-radio__text {
    color: var(--color-dark);
}