.avalance__height{
    position: absolute;
    top: 23%;
    right: 0;
    width: fit-content;
    height: calc(22rem/16);
    font-size: calc(12rem/16);
    line-height: calc(22/12);
    @media screen and (min-width: 1200px){
        right: calc(15rem/16);
    }
}
.avalance__height--lg{

    @media screen and (min-width: 1600px){
        font-size: calc(14rem/16);
        font-family: var(--font-bold-family);
    }
}
.avalance__title{
    margin-left: 26px;
    margin-bottom: 10px;
}
.avalance__description{
    margin-top: calc(5rem/16);
}
.avalance__img{
    max-height: clamp(calc(48rem/16), 4vw, calc(70rem/16));
    @media screen and (min-width: 1600px){
        max-height: 100%;
    }
}