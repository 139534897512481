.slide {
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;

    @media screen and (min-width: 768px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.slide--padding-sm {
    padding-top: 25px;
    padding-bottom: 25px;
}

.slide--padding-lg {
    padding-top: 30px;
    padding-bottom: 30px;

    @media screen and (min-width: 768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}
.slide--padding-xl {
    padding-top: 40px;
    padding-bottom: 40px;

    @media screen and (min-width: 768px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.slide--no-bottom-padding.slide--no-bottom-padding {
    padding-bottom: 0;
}
.slide--no-top-padding.slide--no-top-padding {
    padding-top: 0;
}
.slide--bordered {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}
.slide--bordered-lg {
    border-top: 3px solid var(--color-primary);
    border-bottom: 3px solid var(--color-primary);
}

.slide__hl {
    margin-bottom: 25px;

    @media screen and (min-width: 768px) {
        margin-bottom: 50px;
    }
}
.slide__btn {
    @media screen and (min-width: 768px) {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateY(50%) translateX(-50%);
        z-index: 1;
    }
}