.demi-event-intro h2 {
    color: var(--color-primary);
    text-transform: none;
    font-size: 20px;
    line-height: calc(45/35);

    @media screen and (min-width: 768px) {
        font-size: 35px;
    }
}

.demi-event-detail-content-block--intro {
    padding-top: 30px;
    padding-bottom: 30px;

    @media screen and (min-width: 768px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

/* paging */
.demi-event-paging {
    font-size: 16px;
    margin: 0 0 30px;

    @media screen and (min-width: 768px) {
        font-size: 18px;
        margin: 0 -40px 30px;
    }
}
.row--has-demi-event-teaser + .demi-event-paging {
    margin-top: 50px;
}

/* event teaser row gutter */
.row--has-demi-event-teaser {
    margin-top: -30px;

    @media screen and (min-width: 768px) {
        margin-top: -60px;
    }
}
.row--has-demi-event-teaser > .col,
.row--has-demi-event-teaser > [class*='col-'] {
    margin-top: 30px;

    @media screen and (min-width: 768px) {
        margin-top: 60px;
    }
}

/* accordion */
.demi-event-detail-content-block .card,
.demi-infrastructure-detail-content-block .card {
    border-radius: 0;
    border: 0;
}
.demi-event-detail-content-block .card-header,
.demi-infrastructure-detail-content-block .card-header {
    background: var(--color-grey-light);
    font-family: var(--font-bold-family);
    font-weight: 400;
    border: 0;
    border-radius: 0;
    padding: 0;
}

.demi-event-detail-content-block .card-header a,
.demi-infrastructure-detail-content-block .card-header a {
    padding: .75rem 1.25rem;
    display: block;
    position: relative;
}
.demi-event-detail-content-block .card-header a:after,
.demi-infrastructure-detail-content-block .card-header a:after {
    content: var(--icon-close);
    font-family: iconfont;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    font-size: 16px;
}
.demi-event-detail-content-block .card-header a:hover,
.demi-infrastructure-detail-content-block .card-header a:hover {
    color: var(--color-primary);
}

.demi-event-detail-content {
    background: var(--color-grey-light);
    border-bottom: 1px solid #fff;
    padding: 30px 0;

    @media screen and (min-width: 768px) {
        padding: 50px 0;
    }
}
.demi-event-detail-content__row .container {
    padding: 0;
}
.demi-event-detail-content__row h2 {
    font-size: 20px;
    font-family: var(--font-bold-family);
    font-weight: 400;
    text-transform: none;
}

/* grid teaser */
.demi-event-teaser-grid {
    font-size: 16px;
    line-height: 1.4;

    @media screen and (min-width: 768px) {
        font-size: 18px;
    }
}
.demi-event-teaser-grid__title {
    font-size: 20px;
    line-height: 1.2;
    text-transform: none;
    margin-bottom: 5px;

    @media screen and (min-width: 768px) {
        font-size: 25px;
        margin-bottom: 8px
    }
}
.demi-event-teaser-grid__dates {
    color: #7b7b7b;
    margin-bottom: 6px;

    @media screen and (min-width: 768px) {
        margin-bottom: 14px;
    }
}
.demi-event-teaser-grid__body {
    flex-grow: 1;
    padding-top: 8px;
}

.demi-event-main-content .breadcrumb {
    padding-top: 15px;

    @media screen and (min-width: 768px) {
        padding-top: 30px;
    }
}