.dropdown-menu {
    margin-top: 0;
    border: 0;
    padding: 0;
    min-width: 80px;
    width: 100%;
    box-shadow: 0 1px 4px rgba(0,0,0,.2);
}
.dropdown-item {
    padding: 3px 15px;
}
.dropdown-item:hover {
    background: var(--color-primary);
    color: #fff;
}