.data-row {
    font-size: 16px;

    @media screen and (min-width: 1200px) {
        font-size: 18px;
    }

    @media screen and (min-width: 1400px) {
        font-size: 20px;
    }
}
.data-row + .data-row{
    border-top:2px solid rgba(255, 255, 255, .5);
    margin-top: 2px;
    padding-top: 2px;

    @media screen and (min-width: 1200px) {
        margin-top: 12px;
        padding-top: 12px;
    }
}
@media screen and (min-width: 1200px) {
    .data-row > .row + .row {
        margin-top: 7px;
    }
}
.navbar-main__dropdown .data-row {
    font-size: 16px;
}
.navbar-main__dropdown .data-row + .data-row {
    border-top:2px solid rgba(0, 0, 0, .3);
}

.bg-grey-light .data-row + .data-row {
    border-color: rgba(30, 30, 30, 0.5);
}