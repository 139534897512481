.pagination {
    margin: 0;
    @media screen and (max-width: 767px) {
        margin-top: 20px;
    }
}
.page-link {
    color: var(--color-dark);
    font-size: 16px;
    line-height: 1;
    width: 40px;
    height: 40px;
    padding: 13px 8px;
    text-align: center;
    border: 0;

    @media screen and (min-width: 768px) {
        font-size: 20px;
        width: 53px;
        height: 53px;
        padding: 17px 10px;
    }
}
.page-link:hover,
.page-link:focus {
    background: var(--color-primary);
    color: #fff;
}
.page-link__arrow {
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    @media screen and (min-width: 768px) {
        font-size: 15px;
    }
}
.page-link__arrow.icon-rotate-180 {
    transform: translateY(-50%) translateX(-50%) rotate(180deg);
}
.page-item.active .page-link {
    z-index: 1;
}