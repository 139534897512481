.navbar-container {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;

    @media screen and (min-width: 768px) {
        position: relative;
        transform: translateZ(0);
    }
}

@media screen and (min-width: 768px) {
    .modal-open .navbar-container {
        right: 17px;
    }
}

.navbar-container.navbar-container--landingpage{
    position: absolute;
    @media screen and (min-width: 768px) {
        position: fixed;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.42+0,0+100 */
        background: -moz-linear-gradient(top, rgba(0,0,0,0.42) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,0,0,0.42) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0,0,0,0.42) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6b000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }
}
.navbar-container.navbar-container--fixed,
.navbar-container.is-affix {
    position: fixed;
}
.navbar-container.is-transitioning {
    position: absolute;
}
.navbar-container.is-affix--offset {
    transform: translateY(-80px) translateZ(0);
}


.is-affix--offset .navbar-brand {
    transform: translateY(-50px) translateZ(0);
    opacity: 0;
}
.is-affix--offset .navbar-inline__left {
    transform: translateX(0);
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    .navbar-container.is-affix {
        min-width: 982px;
    }
}