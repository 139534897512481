.card {
    border-radius: 0;
    font-size: 18px;
}
.card__head {
    position: relative;
}
.card__head__badge {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 10px;
}

/* colors */
.card--blue .card-header,
.accordion > .card.card--blue > .card-header {
    background: var(--color-blue);
    color: #fff;
}

.card--primary .card-header,
.accordion > .card.card--primary > .card-header {
    background: var(--color-primary);
    color: #fff;
}