.breadcrumb {
    padding: 0 0 5px;

    @media screen and (min-width: 768px) {
        padding-bottom: 20px;
    }
}

.breadcrumb-item {
    display: inline-block;
    font-size: 14px;

    @media screen and (min-width: 768px) {
        font-size: 17px;
    }
}

.breadcrumb-item:before {
    transform: rotate(180deg);
    font-family: 'iconfont';
    font-size: 10px;

    @media screen and (min-width: 768px) {
        font-size: 12px;
        padding-right: 14px;
    }
}

.breadcrumb-item a:hover {
    color: var(--color-primary);
    text-decoration: none;
}