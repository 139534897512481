.acco-teaser{
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.07);
    padding: 17px 20px;
    font-size: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.acco-teaser__img{
    flex: 0 0 auto;
}

.acco-teaser__title{
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-top: 7px;
    margin-bottom: 5px;
    font-family: var(--font-bold-family);
}

.star-holder{
    font-size: 12px;
    color: #FFCB00;
    margin-left: auto;
    flex: 0 0 auto;
    width: auto;
}

.icon-text .icon {
    display: block;
    margin-right: 5px;
    line-height: 1;
    margin-top: 3px;
}

.acco-teaser .icon-text .icon{
    width: 16px;
}

.acco-teaser .rating__score{
    font-size: 11px;
}

.acco-teaser__rating-text{
    font-size: 13px;
    vertical-align: -2px;
}

.acco-teaser .rating{
    color: #468F01;
}

.acco-teaser .rating__score{
    background: #468F01;
}

.acco-teaser .trustyou-logo{
    height: 8px;
    background-size: contain;
}

.acco-teaser__best-price .badge{
    background-color: #277ECC;
    font-size: 12px;
    color: #fff;
    font-weight: normal;
    padding: 5px 7px;
}

.acco-teaser__price{
    font-family: var(--font-bold-family);
}

.acco-teaser__detail-btn{
    background-color: #468F01;
    color: #fff;
    font-size: 14px;
    font-family: var(--font-bold-family);
    padding: 6px 15px;
    width: 100%;
}

.acco-teaser__detail-btn:hover{
    color: #fff;
}

.acco-teaser__flex-wrapper{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.acco-teaser__select{
    font-size: 14px;
    padding: 6px 15px;
    width: 100%;
    color: var(--color-default);
}