.circle-badge {
    width: 100%;
    padding: 16px 15px 14px;
    font-size: 14px;
    line-height:calc(20/14);
    position: relative;

    @media screen and (min-width: 768px) {
        border-radius: 100%;
        width: 150px;
        height: 150px;
        padding: 33px 10px;
    }
}
.circle-badge + .circle-badge {
    @media screen and (min-width: 768px) {
        margin-left: 10px;
    }
}
.circle-badge__lg-text{
    margin: 0 4px;
    display: inline-block;

    @media screen and (min-width: 768px) {
        margin: 0;
        display: block;
        font-size: 45px;
        line-height: 1;
    }
}
.circle-badge--price:after {
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    right: 0;
    bottom: 6px;
    border: 1px dashed #fff;
    border-left: 0;
    border-right: 0;

    @media screen and (min-width: 768px) {
        border-radius: 100%;
        border: 2px dashed #fff;
        top: 8px;
        left: 8px;
        right: 8px;
        bottom: 8px;
    }
}

.circle-badge__logo {
    width: 30px;
    height: auto;
    margin-left: 10px;
    vertical-align: -0.5em;

    @media screen and (min-width: 768px) {
        width: 42px;
        margin-left: 0;
        vertical-align: unset;
    }
}

.hero-badge{
    position: fixed;
    bottom: 0;
    z-index: 3;
    cursor: pointer;

    @media screen and (min-width: 768px) {
        bottom: 3rem;
        z-index: 3;
    }
}

.hero-badge .circle-badge{
    background-color: #b31939;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 18px 18px;
    font-size: 0.9rem;
    line-height: 1.3;
    width: 100vw;
    box-shadow: 0 0 calc(5rem / 16) rgba(0,0,0,.3);

    @media screen and (min-width: 768px) {
        padding: 33px 18px;
        font-size: 1.1rem;
        width: 150px;
        box-shadow: none;
    }
}

.hero-badge .circle-badge:hover{
    background-color: var(--color-secondary);
}

.hero-badge.hero-badge--right{
    right: 0;

    @media screen and (min-width: 768px) {
        right: 3rem;
    }
}

.hero-badge.hero-badge--left{
    left: 0;

    @media screen and (min-width: 768px) {
        left: 3rem;
    }
}

.circle-badge.circle-badge--primary {
    background: var(--color-primary);
}
.circle-badge.circle-badge--primary:hover {
    background: var(--color-primary-dark);
}
.circle-badge.circle-badge--blue {
    background: var(--color-blue);
}
.circle-badge.circle-badge--blue:hover {
    background: #00AEC7;
}