body {
    position: relative;
}
.page-wrapper {
    clear: both;
    background: #ffffff;
    position: relative;
    padding-top: 55px;

    @media screen and (min-width: 768px) {
        padding-top: 0;
    }
}
.page-wrapper.page-wrapper--landingpage {
    padding-top: 0;
}
.container {
    width: 100%;

    @media screen and (min-width: 768px) {
        max-width: calc(1520px + 80px); /* 80px padding */
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }
}

.container.container-narrow,
.container-narrow {
    max-width: 1160px;
    margin: 0 auto;
}
.container.container-sm,
.container-sm {
    max-width: 850px;
    margin: 0 auto;
}
.container.container-wide {
    max-width: 1850px;
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    body {
        width: 982px;
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: 320px;
        max-width: 100%;
        overflow-x: hidden;
    }
    html {
        overflow-x: hidden;
    }
    .container--no-padding-xs {
        padding-left: 0;
        padding-right: 0;
    }
}