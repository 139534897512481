.webcam-teaser {
    position: relative;
}
.webcam-teaser:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(68, 131, 50, 0.6) 0%, rgba(51, 81, 2, 0.6) 100%);
    opacity: 0;
    transition: opacity 250ms ease-in-out;
}
.webcam-teaser:hover:after {
    opacity: 1;
}
.webcam-teaser__info {
    position: relative;
    bottom: 0;
    left: 0;
    padding: 15px 20px 15px 60px;
    font-size: 14px;
    line-height: calc(22/16);
    max-width: 100%;
    z-index: 1;
    display: block;

    @media screen and (min-width: 768px) {
        position: absolute;
        font-size: 16px;
        padding: 15px 20px 15px 90px;
    }
}
.webcam-teaser__info__icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    line-height: 0;

    @media screen and (min-width: 768px) {
        left: 22px;
        font-size: 43px;
    }
}
.webcam-teaser__info__hl{
    font-size: 18px;
    line-height: 1.2;

    @media screen and (min-width: 768px) {
        font-size: 22px;
    }
}

/*webcam teaser with video */
.webcam-teaser--video:before {
    content: var(--icon-play);
    font-family: "iconfont";
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: rgba(30, 30, 30, 0.8);
    color: #fff;
    font-size: 50px;
    z-index: 1;
    top: 0;
    margin-top: 32%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    padding: 10px 35px;
    transition: background 250ms ease-in-out;

    @media screen and (min-width: 768px) {
        margin-top: 0;
        top: 50%;
        left: 50%;
        font-size: 75px;
        padding: 20px 54px;
        width: 150px;
        height: 150px;
    }
}

@media screen and (min-width: 768px) {
    .webcam-teaser--sm.webcam-teaser--video:before {
        top: 45%;
        width: 100px;
        height: 100px;
        font-size: 48px;
        padding: 15px 35px;
    }
}