.temperature {
    margin-top: 15px;
}
.temperature__value {
    position: relative;
    display: inline-block;
    line-height: 1;
/*    padding-right: 38px;*/
    font-size: 50px;

    @media screen and (min-width: 1200px) {
        font-size: 60px;
    }
}
.temperature__value__small {
    font-size: 17px;
    position: absolute;
    right: 0;
    top: 3px;

    @media screen and (min-width: 1200px) {
        font-size: 20px;
        top: 5px;
    }
}
.temperature__value__small--min {
    top: auto;
    bottom: 3px;

    @media screen and (min-width: 1200px) {
        top: auto;
        bottom: 5px;
    }
}
.temperature__title{
    margin-bottom: 8px;

    @media screen and (min-width: 1200px) {
        margin-bottom: 12px;
    }
}
.temperature__icon {
    font-size: 46px;
    margin-left: 12px;
    vertical-align: -0.1em;

    @media screen and (min-width: 1200px) {
        font-size: 50px;
        vertical-align: -0.05em;
    }
}