.consent{
    background: var(--color-grey);
}
.consent__overlay{
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1;
    justify-content: center;
    gap: calc(15rem/16);
    color: #fff;
    padding: calc(8rem/16);
}
