.wysiwyg a {
    color: var(--color-primary);
}
.wysiwyg a:hover {
    color: #648100;
}
.wysiwyg ul {
    list-style: none;
    padding: 0;
}
.wysiwyg ul > li {
    position: relative;
    padding-left: 18px;

    @media screen and (min-width: 768px) {
        padding-left: 24px;
    }
}
.wysiwyg ul > li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 8px;
    height: 8px;
    background: var(--color-primary);

    @media screen and (min-width: 768px) {
        width: 10px;
        height: 10px;
        top: 10px;
    }
}

.wysiwyg.wysiwyg--sm ul > li:before {
    left: calc(2rem / 16);
    width: calc(6rem / 16);
    height: calc(6rem / 16);

    @media screen and (min-width: 768px) {
        top: calc(12rem / 16);
    }
}
@media screen and (min-width: 768px) {
    .wysiwyg.two-col {
        column-count: 2;
        column-gap: 110px;
    }
}

/* table */
.wysiwyg table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;

    @media screen and (max-width: 767px) {
        display: block;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
}
.wysiwyg table thead th,
.wysiwyg table tbody th,
.table thead th,
.table tbody th {
    vertical-align: bottom;
    background: var(--color-dark);
    color: #fff;
    font-size: 18px;
    font-family:var(--font-bold-family);
    font-weight: 400;

    @media screen and (min-width: 768px){
        font-size: 20px;
    }
}
.wysiwyg table td, .wysiwyg table th {
    padding: 12px 15px;
    vertical-align: top;
    font-size: 16px;

    @media screen and (min-width: 768px){
        font-size: 18px;
    }
}
.wysiwyg tbody tr {
    background: #fff;
}
.wysiwyg tbody tr:nth-of-type(odd) {
    background-color: var(--color-grey-light);
}

.read-more.collapsed .read-more__more,
.read-more__less {
    display: block;
}
.read-more.collapsed .read-more__less,
.read-more__more {
    display: none;
}