@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1741336404325/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1741336404325/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1741336404325/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
                    display: inline-block;
                    font-family: "iconfont";
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 1;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    transform: translate(0, 0);
                }

.icon-lg {
                    font-size: 1.3333333333333333em;
                    line-height: 0.75em;
                    vertical-align: -15%;
                }
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
                    width: 1.2857142857142858em;
                    text-align: center;
                }

.icon-ul {
                    padding-left: 0;
                    margin-left: 2.14285714em;
                    list-style-type: none;
                }
.icon-ul > li {
                    position: relative;
                }
.icon-li {
                    position: absolute;
                    left: -2.14285714em;
                    width: 2.14285714em;
                    top: 0.14285714em;
                    text-align: center;
                }
.icon-li.icon-lg {
                                        left: -1.85714286em;
                                    }

.icon-rotate-90 {
                    transform: rotate(90deg);
                }
.icon-rotate-180 {
                    transform: rotate(180deg);
                }
.icon-rotate-270 {
                    transform: rotate(270deg);
                }
.icon-flip-horizontal {
                    transform: scale(-1, 1);
                }
.icon-flip-vertical {
                    transform: scale(1, -1);
                }
:root .icon-rotate-90,
                      :root .icon-rotate-180,
                                            :root .icon-rotate-270,
                                                                  :root .icon-flip-horizontal,
                                                                                        :root .icon-flip-vertical {
                                                                                                                  filter: none;
                                                                                                              }

.icon-arrow-down:before { content: "\EA01" }
.icon-arrow-left:before { content: "\EA02" }
.icon-arrow-right:before { content: "\EA03" }
.icon-arrow:before { content: "\EA04" }
.icon-avalanche-1:before { content: "\EA05" }
.icon-avalanche-2:before { content: "\EA06" }
.icon-avalanche-3:before { content: "\EA07" }
.icon-avalanche-4:before { content: "\EA08" }
.icon-avalanche-5:before { content: "\EA09" }
.icon-bed:before { content: "\EA0A" }
.icon-blog:before { content: "\EA0B" }
.icon-calendar:before { content: "\EA0C" }
.icon-chat-arrow:before { content: "\EA0D" }
.icon-check:before { content: "\EA0E" }
.icon-close:before { content: "\EA0F" }
.icon-couple:before { content: "\EA10" }
.icon-elements:before { content: "\EA11" }
.icon-facebook:before { content: "\EA12" }
.icon-family:before { content: "\EA13" }
.icon-friends:before { content: "\EA14" }
.icon-fullscreen-close:before { content: "\EA15" }
.icon-fullscreen:before { content: "\EA16" }
.icon-googleplus:before { content: "\EA17" }
.icon-infrastruktur:before { content: "\EA18" }
.icon-instagram:before { content: "\EA19" }
.icon-jobsearch:before { content: "\EA1A" }
.icon-kunst:before { content: "\EA1B" }
.icon-language:before { content: "\EA1C" }
.icon-linkedin:before { content: "\EA1D" }
.icon-live:before { content: "\EA1E" }
.icon-mail:before { content: "\EA1F" }
.icon-marker:before { content: "\EA20" }
.icon-menu:before { content: "\EA21" }
.icon-merkliste:before { content: "\EA22" }
.icon-minus:before { content: "\EA23" }
.icon-phone:before { content: "\EA24" }
.icon-photo:before { content: "\EA25" }
.icon-pinterest:before { content: "\EA26" }
.icon-play:before { content: "\EA27" }
.icon-plus:before { content: "\EA28" }
.icon-prima-blog:before { content: "\EA29" }
.icon-profil:before { content: "\EA2A" }
.icon-reset:before { content: "\EA2B" }
.icon-restaurant:before { content: "\EA2C" }
.icon-scroll:before { content: "\EA2D" }
.icon-search:before { content: "\EA2E" }
.icon-sehenswuerdigkeiten:before { content: "\EA2F" }
.icon-share:before { content: "\EA30" }
.icon-sport:before { content: "\EA31" }
.icon-sportshoe:before { content: "\EA32" }
.icon-star:before { content: "\EA33" }
.icon-tiktok:before { content: "\EA34" }
.icon-touren:before { content: "\EA35" }
.icon-trinken:before { content: "\EA36" }
.icon-twitter:before { content: "\EA37" }
.icon-verkehr:before { content: "\EA38" }
.icon-video:before { content: "\EA39" }
.icon-warenkorb:before { content: "\EA3A" }
.icon-weather-a:before { content: "\EA3B" }
.icon-weather-b:before { content: "\EA3C" }
.icon-weather-c:before { content: "\EA3D" }
.icon-weather-d:before { content: "\EA3E" }
.icon-weather-e:before { content: "\EA3F" }
.icon-weather-f:before { content: "\EA40" }
.icon-weather-g:before { content: "\EA41" }
.icon-weather-h:before { content: "\EA42" }
.icon-weather-i:before { content: "\EA43" }
.icon-weather-j:before { content: "\EA44" }
.icon-weather-k:before { content: "\EA45" }
.icon-weather-l:before { content: "\EA46" }
.icon-weather-m:before { content: "\EA47" }
.icon-weather-n:before { content: "\EA48" }
.icon-weather-o:before { content: "\EA49" }
.icon-weather-p:before { content: "\EA4A" }
.icon-weather-q:before { content: "\EA4B" }
.icon-weather-r:before { content: "\EA4C" }
.icon-weather-s:before { content: "\EA4D" }
.icon-weather-t:before { content: "\EA4E" }
.icon-weather-u:before { content: "\EA4F" }
.icon-weather-v:before { content: "\EA50" }
.icon-weather-w:before { content: "\EA51" }
.icon-weather-x:before { content: "\EA52" }
.icon-weather-y:before { content: "\EA53" }
.icon-weather-z:before { content: "\EA54" }
.icon-webcam:before { content: "\EA55" }
.icon-wellness:before { content: "\EA56" }
.icon-youtube:before { content: "\EA57" }


        :root {
        --icon-arrow-down: "\EA01";
        --icon-arrow-left: "\EA02";
        --icon-arrow-right: "\EA03";
        --icon-arrow: "\EA04";
        --icon-avalanche-1: "\EA05";
        --icon-avalanche-2: "\EA06";
        --icon-avalanche-3: "\EA07";
        --icon-avalanche-4: "\EA08";
        --icon-avalanche-5: "\EA09";
        --icon-bed: "\EA0A";
        --icon-blog: "\EA0B";
        --icon-calendar: "\EA0C";
        --icon-chat-arrow: "\EA0D";
        --icon-check: "\EA0E";
        --icon-close: "\EA0F";
        --icon-couple: "\EA10";
        --icon-elements: "\EA11";
        --icon-facebook: "\EA12";
        --icon-family: "\EA13";
        --icon-friends: "\EA14";
        --icon-fullscreen-close: "\EA15";
        --icon-fullscreen: "\EA16";
        --icon-googleplus: "\EA17";
        --icon-infrastruktur: "\EA18";
        --icon-instagram: "\EA19";
        --icon-jobsearch: "\EA1A";
        --icon-kunst: "\EA1B";
        --icon-language: "\EA1C";
        --icon-linkedin: "\EA1D";
        --icon-live: "\EA1E";
        --icon-mail: "\EA1F";
        --icon-marker: "\EA20";
        --icon-menu: "\EA21";
        --icon-merkliste: "\EA22";
        --icon-minus: "\EA23";
        --icon-phone: "\EA24";
        --icon-photo: "\EA25";
        --icon-pinterest: "\EA26";
        --icon-play: "\EA27";
        --icon-plus: "\EA28";
        --icon-prima-blog: "\EA29";
        --icon-profil: "\EA2A";
        --icon-reset: "\EA2B";
        --icon-restaurant: "\EA2C";
        --icon-scroll: "\EA2D";
        --icon-search: "\EA2E";
        --icon-sehenswuerdigkeiten: "\EA2F";
        --icon-share: "\EA30";
        --icon-sport: "\EA31";
        --icon-sportshoe: "\EA32";
        --icon-star: "\EA33";
        --icon-tiktok: "\EA34";
        --icon-touren: "\EA35";
        --icon-trinken: "\EA36";
        --icon-twitter: "\EA37";
        --icon-verkehr: "\EA38";
        --icon-video: "\EA39";
        --icon-warenkorb: "\EA3A";
        --icon-weather-a: "\EA3B";
        --icon-weather-b: "\EA3C";
        --icon-weather-c: "\EA3D";
        --icon-weather-d: "\EA3E";
        --icon-weather-e: "\EA3F";
        --icon-weather-f: "\EA40";
        --icon-weather-g: "\EA41";
        --icon-weather-h: "\EA42";
        --icon-weather-i: "\EA43";
        --icon-weather-j: "\EA44";
        --icon-weather-k: "\EA45";
        --icon-weather-l: "\EA46";
        --icon-weather-m: "\EA47";
        --icon-weather-n: "\EA48";
        --icon-weather-o: "\EA49";
        --icon-weather-p: "\EA4A";
        --icon-weather-q: "\EA4B";
        --icon-weather-r: "\EA4C";
        --icon-weather-s: "\EA4D";
        --icon-weather-t: "\EA4E";
        --icon-weather-u: "\EA4F";
        --icon-weather-v: "\EA50";
        --icon-weather-w: "\EA51";
        --icon-weather-x: "\EA52";
        --icon-weather-y: "\EA53";
        --icon-weather-z: "\EA54";
        --icon-webcam: "\EA55";
        --icon-wellness: "\EA56";
        --icon-youtube: "\EA57";
        
        }