.weather-slide {
    margin-bottom: calc(50rem / 16);
}
.weather-slide__content {
    background-size: cover;
    background-position: center;
    padding: 25px 0 25px;
    position: relative;

    @media screen and (min-width: 768px) {
        padding: 55px 0 0;
    }
}
.weather-slide__content:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(180deg, #448332 0%, #335102 100%);
    opacity: .86;
}
.weather-slide__content__hl {
    margin-bottom: 5px;

    @media screen and (min-width: 768px) {
        margin-bottom: 35px;
    }
}
.weather-slide__content__col{
    margin-top: 30px;

    @media screen and (min-width: 768px) {
        margin-top: 0;
        line-height: calc(26/18);
        font-size: 18px;
    }
}
[class*=col-]:first-child .weather-slide__content__col {
    margin-top: 0;
}
.weather-slide__content__col__hl {
    line-height: 1;
    margin-bottom: 5px;
    font-size: 20px;

    @media screen and (min-width: 1200px) {
        font-size: 30px;
        margin-bottom: 10px;
    }
}
.weather-slide__content__bottom {
    position: absolute;
    bottom: -22px;
    left: 0;
    right: 0;

    @media screen and (min-width: 768px) {
        bottom: 12px;
    }
}
.weather-slide__collapse-body {
    position: relative;
    padding: 20px 0;

    @media screen and (max-width: 767px) {
        padding: 20px 0 50px;
        margin: 0 -15px;
    }
}
.weather-slide__collapse-body__close{
    position: absolute;
    font-size: 18px;
    top: 15px;
    right: 10px;
    z-index: 1;

    @media screen and (min-width: 768px) {
        font-size: 22px;
        top: 25px;
        right: 0;
    }
}
@media screen and (min-width: 768px) {
    .weather-slide__collapse-body [class*=col-] + [class*=col-] .weather-box:before {
        content: '';
        position: absolute;
        left: 0;
        width: 1px;
        top: 50%;
        transform: translateY(-50%);
        height: 38%;
        background: var(--color-default);
        opacity: .5;
    }
}