.custom-checkbox {
    position: relative;
    padding-left: 30px;
}
.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}
.custom-checkbox:after {
     clear: both;
}
.custom-checkbox input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    float: left;
    overflow: hidden;
    background: #ffffff;
    color: var(--color-primary);
    width: 20px;
    height: 20px;
    font-size: 11px;
    line-height: 14px;
    text-indent: -9999px;
    position: absolute;
    left: 0;
    top: 0;
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.custom-checkbox input[type="checkbox"]:checked + .custom-checkbox__box {
    text-indent: 0;
}
.custom-checkbox input[type="checkbox"]:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(140, 180, 0, 0.6);
}
.custom-checkbox__text {
    vertical-align: -.1em;
}
.custom-checkbox label {
    padding-left: 0;
    font-size: 16px;
    cursor: pointer;
    margin: 0;
    line-height: calc(20/16);
    display: block;
}

/* large checkbox */
.custom-checkbox--lg {
    padding-left: 35px;
}
.custom-checkbox--lg label {
    font-size: 20px;
    line-height: 1;
}
.custom-checkbox--lg .custom-checkbox__box {
    top: 0;
    width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 25px;
}

/* bordered checkbox */
.custom-checkbox-bordered .custom-checkbox__box {
    background: transparent;
    border: 1px solid var(--color-dark);
}
.custom-checkbox-bordered .custom-checkbox__box:after {
    background: transparent;
}

/* grey checkbox */
.custom-checkbox-grey .custom-checkbox__box {
    background: var(--color-grey-light);
}
.custom-checkbox-grey .custom-checkbox__box:after {
    background: var(--color-grey-light);
}

.custom-checkbox__text a {
    color: var(--color-primary);
}
.custom-checkbox__text a:hover {
    color: #648100;
}
.custom-checkbox__text > p {
    margin-bottom: 0;
}