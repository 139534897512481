.notification {
    position: fixed;
    top: 10px;
    right: 10px;
    left: 10px;
    background: #fff;
    padding: 15px;
    box-shadow: 0 0 9px rgba(0,0,0,.25);
    z-index: 1000;
    font-size: 16px;
    transform: translateY(-130%);
    opacity: 0;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
    pointer-events: none;

    @media screen and (min-width: 768px) {
        left: auto;
        width: 480px;
        top: 30px;
        right: 30px;
        padding: 30px;
    }
}
.is-active.notification {
    transform: translateY(0);
    opacity: 1;
    animation: modalFadeIn 250ms 1 ease-in-out;
    pointer-events: auto;
}

@keyframes modalFadeIn {
    from {
        transform: translateY(-130%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media screen and (min-width: 768px) {
    .notification__title {
        font-size: 18px;
    }
}

.notification__close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
    font-size: 16px;
}