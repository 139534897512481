.datepicker {
    position: relative;
}
.datepicker:before {
    font-family: "iconfont";
    content: var(--icon-calendar);
    color: var(--color-primary);
    speak: none;
    position: absolute;
    top: 50%;
    right: 15px;
    font-size: 16px;
    pointer-events: none;
    transform: translateY(-50%);
}

.ui-datepicker {
    background: var(--color-grey-light);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ui-datepicker.datepicker-white {
    background: #fff;
}
.ui-datepicker td a, .ui-datepicker td span {
    text-align: center;
}
.ui-state-highlight,
.ui-state-hover,
.ui-state-highlight:hover,
.ui-state-hover:hover {
    background: var(--color-primary);
    color: #fff;
}
.ui-state-disabled,
.ui-state-disabled:before {
    color: #8e8e8e;
}
.ui-datepicker-next:before,
.ui-datepicker-prev:before {
    font-family: "iconfont";
    content: var(--icon-arrow-left);
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.ui-datepicker-next:before {
    transform: rotate(180deg) translateY(50%) translateX(50%);
}
.ui-datepicker-prev.ui-state-hover,
.ui-datepicker-next.ui-state-hover {
    background: transparent;
    left: 2px;
    top: 2px;
}
.ui-datepicker-next.ui-state-hover {
    left: auto;
    right: 2px;
}
.ui-datepicker-prev.ui-state-hover:not(.ui-state-disabled):before,
.ui-datepicker-next.ui-state-hover:not(.ui-state-disabled):before {
    color: var(--color-primary);
}