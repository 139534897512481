.hero-img {
    position: relative;
}
.hero-img__content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);

    @media screen and (min-width: 768px) {
        padding: 10px 40px 12%;
    }
    @media screen and (min-width: 1200px) {
        padding-left: 80px;
        padding-right: 80px;
    }
    @media screen and (min-width: 1400px) {
        padding-right: 30%;
        padding-bottom: 10%;
    }
}

.hero-img__content.hero-img__content--center-center {
    padding:15px;
    left:50%;
    right:auto;
    top:50%;
    bottom:auto;
    transform: translateX(-50%) translateY(-50%);
    width:100%;
    max-width:750px;
    background: transparent;
}
.hero-img__content__intro{
    font-size: 20px;
    line-height: calc(60/40);

    @media screen and (min-width: 768px) {
        font-size: 40px;
    }
}
.hero-img__content__text{
    font-size: 24px;
    line-height: calc(28/24);
}

.hero-img__content__hl {
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
        margin-bottom: 20px;
        font-size: 60px;
    }
    @media screen and (min-width: 992px) {
        font-size: 70px;
    }
    @media screen and (min-width: 1200px) {
        font-size: 80px;
    }

}


.hero-img__content__hl-lp {
    margin-bottom: 20px;
    font-size: 44px;
    text-transform:none;

    @media screen and (min-width: 768px) {
        margin-bottom: 20px;
        font-size: 60px;
    }
    @media screen and (min-width: 992px) {
        font-size: 70px;
    }
    @media screen and (min-width: 1200px) {
        font-size: 80px;
    }

}

/* small hero-img */
.hero-img--sm .hero-img__content {
    @media screen and (min-width: 768px) {
        padding-bottom: 8.5%;
    }
}
.hero-img--sm .hero-img__content__hl {
    margin-bottom: 0;
}

/* scroll info */
.hero-img__scroll {
    position: absolute;
    left: 50%;
    bottom: 12px;
    transform: translateX(-50%);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.hero-img__scroll__icon {
    font-size: 35px;
    line-height: 1;

    @media screen and (min-width: 1200px) {
        font-size: 50px;
    }
}


/* hero-img--loyality*/
.hero-img--loyality {
    @media screen and (max-width: 767px) {
        background-size: cover;
        background-position: center center;
    }
}
.hero-img__content-loyality {
    padding-top: 60px;
    text-shadow: 0 2px 4px rgba(0,0,0,0.3);
    font-size: 22px;

    @media screen and (min-width: 768px) {
        padding: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    @media screen and (min-width: 1200px) {
        padding-bottom: 45px;
    }
}


/* hero image badges */
@media screen and (min-width: 768px) {
    .hero-img__badge-container {
        position: absolute;
        z-index: 1;
        bottom: 0;
        transform: translateY(50%);
        right: 15%;
    }
}

/* full height hero */
.hero-img--full-height-lp {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    min-height: 600px;
    height: 100vh;
}
@media screen and (min-width: 768px) {
    .hero-img--full-height {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 600px;
        height: 90vh;
    }
    .hero-img__bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: auto;
    }
}

.hero-img--full-height .hero-img__content {
    @media screen and (min-width: 768px) {
        padding-bottom: 150px;
    }
    @media screen and (min-width: 1400px) {
        padding-bottom: 190px;
    }
}

.matomoHsr .hero-img {
    min-height: 200px;
}

.hero-img__scroll-icons{
    position: absolute;
    bottom: 8%;
    left: calc(50vw - 1.25rem);
    font-size: 1.25rem;
    color: white;
    padding-bottom: 7px;
    animation: bounce 1s infinite ease;
 }

@keyframes bounce {
    0%   {
        transform: scale(1,1);
        padding-bottom: 0;
    }
    10%  {
        transform: scale(1.05,.95);
    }
    30%  {
        transform: scale(.95,1.05);
        padding-bottom: 10px;
    }
    50%  {
        transform: scale(1.05,.95);
    }
    57%  {
        transform: scale(1,1);
        padding-bottom: 5px;
    }
    64%  {
        transform: scale(1,1);
    }
    100% {
        transform: scale(1,1);
        padding-bottom: 0;
    }
}

.hero-img__scroll-icons:hover,
.hero-img__scroll-icons:focus,
.hero-img__scroll-icons:active,
.hero-img__scroll-icons:visited {
    color: white;
}

.hero-img__scroll-hint{
    position: absolute;
    right: 5%;
    top: 4%;
    height: 96vh;
    z-index: 999;
    color: white;
    @media screen and (max-width: 767px){
        display: none;
    }
}

.hero-img__scroll-hint-btn{
    color: white;
    font-size: 1.25rem;
    @media screen and (max-width: 1399px){
        font-size: 1.125rem;
    }
    @media screen and (max-width: 1199px){
        font-size: 1rem;
    }
}

.hero-img__scroll-hint-line{
    position: absolute;
    bottom: 0;
    background-color: white;
    height: 95%;
    width: 0.1rem;
    left: 50%;
    transform: translateX(-50%);
}

.hero-img__scroll-hint-line:before{
    content:'';
    position: absolute;
    top: 0;
    background-color: var(--color-primary);
    height: 5rem;
    width: 0.15rem;
    left: 50%;
    transform: translateX(-50%);
}

/* scroll info */
.scroll-down {
    position: absolute;
    left: 51%;
    bottom: 0;
    z-index: 3;
    font-size: calc(20rem / 16);
    line-height: calc(24/20);
    font-family: var(--font-default-medium);
    font-weight: 400;
    width: calc(112rem / 16);
    transform: rotate(-90deg) translateY(100%);
    transform-origin: left bottom;
    color: #fff;
    padding-left: calc(60rem / 16);
}
.scroll-down:after,
.scroll-down:before {
    content: '';
    background: rgba(255,255,255,.5);
    position: absolute;
    top: 50%;
    left: 0;
    width: calc(50rem / 16);
    height: calc(2rem / 16);
}
.scroll-down:after {
    animation: fillAnimation 1.6s ease-in-out infinite;
    background: var(--color-primary);
}
.scroll-down:hover,
.scroll-down:focus {
    color: var(--color-primary);
}
@keyframes fillAnimation {
    0% {
        transform-origin: right center;
        transform: scaleX(0);
    }
    50% {
        transform-origin: right center;
        transform: scaleX(1);
    }
    50.1% {
        transform-origin: left center;
        transform: scaleX(1);
    }
    100% {
        transform-origin: left center;
        transform: scaleX(0);
    }
}
