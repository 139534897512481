.img-cropper__img {
    max-width: 100%;
}
.img-cropper-dropzone {
    background: #fff;
    cursor: pointer;
}
.img-cropper-dropzone.img-cropper-dropzone--over {
    border: 2px dashed grey;
}
.img-cropper-dropzone__label {
    font-size:calc(18rem/16);
    padding:calc(20rem/16);
}
.img-cropper__quality-legend {
    font-size:calc(16rem/16);
    padding-top:calc(20rem/16);
    padding-bottom:calc(20rem/16);
}
.img-cropper__preview {
    padding-bottom:calc(20rem/16);
    @media screen and (max-width: 767px) {
        padding-top:calc(20rem/16);
    }
}