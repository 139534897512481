.price-list__row {
    position: relative;
}
.price-list__row:before {
    content: '';
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--color-dark);
    opacity: 0.4;
}
.price-list__row__col {
    padding: 0 8px;
    margin: 0 -8px;
    background: var(--color-grey-light);
}