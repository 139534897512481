.figure {
    position: relative;
}
.figure:after {
    content: var(--icon-fullscreen);
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    background: var(--color-dark);
    font-size: 18px;
    padding: 14px;
    transition: color 250ms ease-in-out;

    @media screen and (min-width: 768px) {
        font-size: 28px;
        padding: 26px;
        width: 80px;
        height: 80px;
    }
}
.figure:hover:after {
    color: var(--color-primary);
}

/* video button */
.figure--video:after {
    content: var(--icon-video);
}
.figure--video__play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    background: #fff;
    color: var(--color-primary);
    border-radius: 50%;
    transition: box-shadow 250ms ease-in-out;

    @media screen and (min-width: 786px) {
        width: 130px;
        height: 130px;
    }
}
.figure--video__play-btn__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
    margin-left: 3px;

    @media screen and (min-width: 786px) {
        font-size: 55px;
        margin-left: 6px;
    }
}
.figure--video:hover .figure--video__play-btn {
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.65);
}