body {
    color: var(--color-text-default);
    font-family:var(--font-regular-family);
    font-weight: 400;
    font-size: 14px;
    line-height: calc(24 / 14);
}
@media screen and (min-width: 768px){
    body {
        font-size: 18px;
        line-height: calc(30 / 18);
    }
}

.font-bold, strong, b {
    font-family: var(--font-bold-family);
    font-weight: 400;
}
.font-regular {
    font-family:var(--font-regular-family);
    font-weight: 400;
}
.font-pacifico {
    font-family:var(--font-pacifico-family);
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    margin-bottom: 20px;
    color: inherit;
    text-transform: uppercase;
    font-weight: 400;
}
h1, .h1 {
    /*font-size: calc(28rem / 16);
    line-height: calc(34/30);   */
    font-size: 25px;
    line-height: 1;
    font-family:var(--font-bold-family);
    font-weight: 400;

    @media screen and (min-width: 768px) {
        font-size: 70px;
        line-height: calc(85/80);
    }
    @media screen and (min-width: 1200px) {
        font-size: 80px;
    }
}
h1.smaller {
    @media screen and (min-width: 768px) {
        font-size: 50px;
    }
    @media screen and (min-width: 1200px) {
        font-size: 60px;
    }
}
h2, .h2 {
    font-size: 25px;
    line-height: 1;
    font-family:var(--font-bold-family);
    font-weight: 400;
    @media screen and (min-width: 768px) {
        font-size: 45px;
        line-height: calc(50/45);
    }
}
h3, .h3 {
    font-size: 22px;
    line-height: calc(24/17);
    font-family:var(--font-bold-family);
    font-weight: 400;
    @media screen and (min-width: 768px) {
        font-size: 40px;
        line-height: calc(45/40);
    }
}
h4, .h4 {
    font-size: 15px;
    font-family:var(--font-bold-family);
    margin-bottom: 15px;

    @media screen and (min-width: 768px) {
        font-size: 22px;
    }
    @media screen and (min-width: 1200px) {
        font-size: 25px;
        line-height: calc(30/25);
    }
}
h5, .h5 {
    font-size: 15px;
    text-transform: none;

    @media screen and (min-width: 768px) {
        font-size: 20px;
        line-height: calc(30/20);
    }
}

.subtitle {
    font-size: 20px;
    line-height: calc(45/35);

    @media screen and (min-width: 768px) {
        font-size: 35px;
    }
}

.fz18 {
    font-size: 14px;
    @media screen and (min-width: 768px) {
        font-size: 18px;
    }
}