.accordion > .card {
    border: 1px solid white;
}

.accordion > .card > .card-header {
    margin: 0;
    font-family: var(--font-bold-family);
    background-color: #f0f0f0;
    font-size: 1.125rem;
    border: none;
    color: var(--color-default);
@media screen and (max-width: 767px) {
    font-size: .875rem;
}
}
.accordion .accordion__header-link{
    display: block;
    text-align: left;
}

.accordion > .card > .card-body {
    font-size: 1.125rem;
    @media screen and (max-width: 767px) {
    font-size: .875rem;
}
}

.accordion__header-link:not(.collapsed) .accordion__toggle-icon {
    transform: rotate(45deg);
    transition: ease-in-out 0.1s;
}

