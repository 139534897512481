.img-teaser {
    position: relative;
    overflow: hidden;
    transform: translateZ(0);
}
.img-teaser:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(68, 131, 50, 0.6) 0%, rgba(51, 81, 2, 0.6) 100%);
    opacity: 0;
    transition: opacity 250ms ease-in-out;
}
.img-teaser:hover:after {
    opacity: 1;
}
.img-teaser__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 50px 15px 15px;

    @media screen and (min-width: 1200px) {
        padding: 90px 60px 30px;
    }

    @media screen and (min-width: 1400px) {
        padding: 90px 80px 50px;
    }
}
.img-teaser__content:after {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0;
    right:0;
    z-index: -1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    transition: opacity 250ms ease-in-out;
}

.img-teaser:hover .img-teaser__content:after {
    opacity: 0;
}

.img-teaser__content__hl {
    position: relative;
    font-size: 20px;
    line-height: 1;
    text-transform: none;
    margin-bottom: 12px;

    @media screen and (min-width: 1200px) {
        font-size: 28px;
    }
}
.img-teaser__content__hl--decorator:before {
    content: '';
    position: absolute;
    top: -22px;
    left: 0;
    width: 42px;
    height: 5px;
    background: var(--color-default);
}
.text-white .img-teaser__content__hl--decorator:before {
    background: #fff;
}
.img-teaser__content__text{
    margin-bottom: 22px;
    line-height: 1;
    font-size: 16px;

    @media screen and (min-width: 1200px) {
        font-size: 20px;
    }
}
.img-teaser--lg .img-teaser__content__text {
    padding-right: 25%;
    margin-bottom: 35px;
}

/* large teaser */
.img-teaser__content__subtitle {
    font-size: 15px;
    line-height: calc(20/15);
    margin-bottom: 8px;

    @media screen and (min-width: 768px) {
        font-size: 30px;
        line-height: calc(50/30);
        margin-bottom: 4px;
    }
}
.img-teaser--offer .img-teaser__content__subtitle {
    @media screen and (min-width: 768px) {
        font-size: 34px;
        line-height: calc(50/40);
        margin-bottom: 0;
    }
    @media screen and (min-width: 1200px) {
        font-size: 36px;
    }
    @media screen and (min-width: 1400px) {
        font-size: 40px;
    }
}
.img-teaser--offer .img-teaser__content__hl,
.img-teaser--lg .img-teaser__content__hl {
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
        font-size: 38px;
    }
    @media screen and (min-width: 1400px) {
        font-size: 45px;
    }
}

/* image teaser green overlay */
.img-teaser--overlay:after {
    background-image: linear-gradient(180deg, #448332 0%, #335102 100%);
    opacity: 0.84;
}
.img-teaser--overlay:hover:after {
    opacity: .9;
}
.img-teaser--overlay .img-teaser__content:after {
    content: none;
}

/*image teaser dark content*/
.img-teaser__content--dark {
    height: 100%;
    align-items: flex-end;
    display: flex;
}
.img-teaser__content--dark:after {
    background-image: radial-gradient(circle 487px at 0% 100%, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

/* img teaser small content */
.img-teaser--sm-content .img-teaser__content {
    padding: 20px 80px 20px 20px;
}

/* img teaser md content */
@media screen and (min-width: 1200px){
    .img-teaser--md-content .img-teaser__content {
        padding: 30px;
    }
}

/* img teaser badge */
.img-teaser__badge {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    font-size: 16px;
    padding: 4px 15px 3px;
}

.img-teaser__badge--circle {
    border-radius: 50%;
    height: 70px;
    width: 70px;
    text-align: center;
    padding: 5px;
    font-family: var(--font-bold-family);
    font-weight: 400;
    justify-content: center;
    display: flex;
    flex-direction: column;
    top: 10px;
    left: auto;
    right: 10px;
    font-size: 14px;
    line-height: 1.2;

    @media screen and (min-width: 768px) {
        height: 80px;
        width: 80px;
        font-size: 16px;
        top: 20px;
        right: 20px;
    }
}

.img-teaser__badge--circle.is-left{
    left: 10px;
    right: auto;
    @media screen and (min-width: 768px) {
        left: 20px;
    }
}

.img-teaser__badge__logo {
    width: 30px;
    height: auto;
    margin: 5px auto 0;

    @media screen and (min-width: 768px) {
        width: 36px;
    }
}

.img-teaser__badge--unavailable {
    text-transform: uppercase;
    font-family: var(--font-bold-family);
    letter-spacing: 2px;
    position: absolute;
    color: #fff;
    display: block;
    font-size: 1rem;
    padding: .25rem 1rem;
    background-color: var(--color-default);
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
    bottom: 0;
}