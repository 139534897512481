/* row--same-height */
.row--same-height__item {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}
/*
 * the .row--same-height selector has to be after
 * .row--same-height__item to increase the specificity
 * in case of chaining the two selectors
 * (which is needed for nested rows)
 */
.row--same-height,
.row--same-height.slick-slider .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.row--same-height {
    flex-wrap: wrap;
}
.row--same-height.slick-slider {
    display: block;
}
.row--same-height:before,
.row--same-height:after {
    display: none;
}
.row--same-height > .col,
.row--same-height > [class*='col-'],
.row--same-height.slick-slider [ class*='col-'] {
    display: flex;
    flex-direction: column;
    height: auto;
}

@media screen and (min-width: 768px) {
    .row--same-height-sm .row--same-height__item {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }
    /*
     * the .row--same-height selector has to be after
     * .row--same-height__item to increase the specificity
     * in case of chaining the two selectors
     * (which is needed for nested rows)
     */
    .row--same-height-sm,
    .row--same-height-sm.slick-slider .slick-track {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        /*align-items: stretch;*/
        /*justify-content: space-between;*/
        /*overflow: hidden;*/
    }
    .row--same-height-sm.slick-slider {
        display: block;
    }
    .row--same-height-sm:before,
    .row--same-height-sm:after {
        display: none;
    }
    .row--same-height-sm > .col,
    .row--same-height-sm > [class*='col-'],
    .row--same-height-sm.slick-slider [class*='col-'] {
        display: flex;
        flex-direction: column;
        height: auto;
    }
}

.row.row--gutter-with-0 {
    margin-left: 0;
    margin-right: 0;
}
.row.row--gutter-with-0 > .col,
.row.row--gutter-with-0 > [class*='col-'],
.row.row--gutter-with-0 .slick-track > [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
}
.row.row--gutter-with-2 {
    margin-left: -1px;
    margin-right: -1px;
}
.row.row--gutter-with-2 > .col,
.row.row--gutter-with-2 > [class*='col-'] {
    padding-left: 1px;
    padding-right: 1px;
}
.row.row--gutter-with-4 {
    margin-left: -2px;
    margin-right: -2px;
}
.row.row--gutter-with-4 > .col,
.row.row--gutter-with-4 > [class*='col-'] {
    padding-left: 2px;
    padding-right: 2px;
}

.row.row--gutter-with-10 {
    margin-left: -5px;
    margin-right: -5px;
}
.row.row--gutter-with-10 > .col,
.row.row--gutter-with-10 > [class*='col-'] {
    padding-left: 5px;
    padding-right: 5px;
}

.row.row--gutter-with-20 {
    margin-left: -10px;
    margin-right: -10px;
}
.row.row--gutter-with-20 > .col,
.row.row--gutter-with-20 > [class*='col-'] {
    padding-left: 10px;
    padding-right: 10px;
}

.row.row--gutter-with-40 {
    margin-left: -20px;
    margin-right: -20px;
}
.row.row--gutter-with-40 > .col,
.row.row--gutter-with-40 > [class*='col-'],
.row.row--gutter-with-40 .slick-track > [class*='col-'] {
    padding-left: 20px;
    padding-right: 20px;
}

.row.row--gutter-with-50 {
    margin-left: -25px;
    margin-right: -25px;
}
.row.row--gutter-with-50 > .col,
.row.row--gutter-with-50 > [class*='col-'] {
    padding-left: 25px;
    padding-right: 25px;
}

@media screen and (min-width: 768px) {
    .row.row--gutter-with-80 {
        margin-left: -40px;
        margin-right: -40px;
    }
    .row.row--gutter-with-80 > .col,
    .row.row--gutter-with-80 > [class*='col-'],
    .row.row--gutter-with-80 .slick-track > [class*='col-'] {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.row.row--gutter-lg {
    @media screen and (min-width: 768px) {
        margin-left: -20px;
        margin-right: -20px;
    }
    @media screen and (min-width: 1200px) {
        margin-left: -40px;
        margin-right: -40px;
    }
}
.row.row--gutter-lg > .col,
.row.row--gutter-lg > [class*='col-'] {
    @media screen and (min-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    @media screen and (min-width: 1200px) {
        padding-left: 40px;
        padding-right: 40px;
    }
}

/*vertical gutter*/
.list-unstyled[class*='row--vertical-gutter-'] {
    margin-bottom: 0;
}
.row--vertical-gutter-4 {
    margin-top: -4px;
}
.row--vertical-gutter-4 > .col,
.row--vertical-gutter-4 > [class*='col-'] {
    margin-top: 4px;
}
.row--vertical-gutter-10 {
    margin-top: -10px;
}
.row--vertical-gutter-10 > .col,
.row--vertical-gutter-10 > [class*='col-'] {
    margin-top: 10px;
}
.row--vertical-gutter-40 {
    margin-top: -40px;
}
.row--vertical-gutter-40 > .col,
.row--vertical-gutter-40 > [class*='col-'] {
    margin-top: 40px;
}
.row--vertical-gutter-30 {
    margin-top: -30px;
}
.row--vertical-gutter-30 > .col,
.row--vertical-gutter-30 > [class*='col-'] {
    margin-top: 30px;
}
.row--vertical-gutter-60 {
    margin-top: -35px;

    @media screen and (min-width: 768px) {
        margin-top: -60px;
    }
}
.row--vertical-gutter-60 > .col,
.row--vertical-gutter-60 > [class*='col-'] {
    margin-top: 35px;

    @media screen and (min-width: 768px) {
        margin-top: 60px;
    }
}

@media screen and (max-width: 767px) {
    .row--vertical-gutter-20-xs {
        margin-top: -20px;
    }

    .row--vertical-gutter-20-xs > .col,
    .row--vertical-gutter-20-xs > [class*='col-'] {
        margin-top: 20px;
    }
}


.row--offset-negative {
    padding-top: 20px;

    @media screen and (min-width: 768px) {
        margin-top: -70px;
        padding-top: 30px;
    }
    @media screen and (min-width: 1200px) {
        margin-top: -100px;
    }
}

@media screen and (min-width: 768px) {
    .row--offset-negative > .col:before,
    .row--offset-negative > [class*='col-']:before {
        content: '';
        position: absolute;
        left: -999999px;
        top: -30px;
        background: #fff;
        right: 0;
        bottom: -5px;
    }
    .row--offset-negative__content {
        position: relative;
    }
}


/* bordered row */
.row--bordered-cols .col + .col:before,
.row--bordered-cols [class*='col-'] + [class*='col-']:before {
    content: '';
    position: absolute;
    left: -1px;
    top: 40px;
    bottom: 40px;
    width: 2px;
    background: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 767px) {
    .row--bordered-cols .col-6:nth-child(3):before,
    .row--bordered-cols .col-6:nth-child(5):before,
    .row--bordered-cols .col-6:nth-child(7):before,
    .row--bordered-cols .col-6:nth-child(9):before{
        content: none;
    }
}

.row--bordered-simple>[class*=" col"]:not(:first-child), .row--bordered-simple>[class^=col]:not(:first-child) {
    border-left: 1px solid #d8d8d8;
}
.row--bordered-simple-default>[class*=" col"]:not(:first-child), .row--bordered-simple-default>[class^=col]:not(:first-child) {
    border-left: 1px solid var(--color-default);
}