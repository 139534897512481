.offer-teaser__head {
    position: relative;
}
.offer-teaser__head .watchList-button {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 60px;
}
.offer-teaser__body {
    padding: 15px 0;
    font-size: 16px;
    line-height: calc(26/18);

    @media screen and (min-width: 768px) {
        padding: 20px 0;
        font-size: 18px;
    }
}
.offer-teaser__body__hl {
    font-size: 20px;
    line-height: 1;
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
        font-size: 25px;
    }
}
.offer-teaser__body__price {
    font-size: 20px;

    @media screen and (min-width: 768px) {
        font-size: 25px;
    }
}
.offer-teaser__head__trustyou {
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: url(/static/img/logos/trustyou.png);
    background-repeat: no-repeat;
    width: 108px;
    height: 60px;
    padding: 8px 20px;
    color: #2a5caa;
    font-size: 14px;
    line-height: calc(20/14);
}
.offer-teaser__head__trustyou__lg {
    font-family:var(--font-bold-family);
    font-size: 20px;
}