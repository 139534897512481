@import './style/mediaqueries.css';
@import './style/base.css';
@import './style/base-layout.css';
@import './style/icons.css';
@import './style/typo.css';
@import './style/btn.css';
@import './style/icon-btn.css';
@import './style/embed-responsive.css';
@import './style/font-face.css';

/*navigation*/
@import './style/navbar.css';
@import './style/navbar-dropdown.css';
@import './style/navbar-inline.css';
@import './style/navbar-affix.css';
@import './style/navbar-mobile.css';
@import './style/console.css';
@import './style/breadcrumb.css';

@import './style/dropdown.css';

/*content types*/
@import './style/hero-img.css';
@import './style/countdown.css';
@import './style/slider.css';
@import './style/grid-box.css';
@import './style/intro-text.css';
@import './style/tabs.css';
@import './style/slide.css';
@import './style/img-slide.css';
@import './style/quotation-block.css';
@import './style/figure.css';
@import './style/wysiwyg.css';
@import './style/modal.css';
@import './style/collapse-item.css';
@import './style/content-block.css';
@import './style/video.css';
@import './style/data-row.css';
@import './style/percent-circle.css';
@import './style/loyality-info.css';
@import './style/ribbon-badge.css';
@import './style/card.css';
@import './style/avalanche-warning.css';
@import './style/accordion.css';

/* weather */
@import './style/temperature.css';
@import './style/info-box.css';
@import './style/weather-box.css';
@import './style/weather-item.css';
@import './style/weather-slide.css';

/*teaser*/
@import './style/dropdown-teaser.css';
@import './style/img-teaser.css';
@import './style/horizontal-teaser.css';
@import './style/webcam-teaser.css';
@import './style/offer-teaser.css';
@import './style/article-teaser.css';

/* price items */
@import './style/offer-badge.css';
@import './style/price-list.css';
@import './style/circle-badge.css';

/*form elements*/
@import './style/form.css';
@import './style/form-control-grey.css';
@import './style/form-control-label.css';
@import './style/select.css';
@import './style/datepicker.css';
@import './style/checkbox.css';
@import './style/radio.css';
@import './style/radio-btn-tab-group.css';
@import './style/input-box.css';
@import './style/pagination.css';
@import './style/control-box.css';
@import './style/range-slider.css';
@import './style/loading-spinner.css';
@import './style/number-spinner.css';

@import './style/watchlist.css';
@import './style/state-circle.css';
@import './style/author.css';
@import './style/footer.css';

@import './style/google-map.css';
@import './style/interactive-map.css';
@import './style/map-infobox.css';
@import './style/lightbox.css';
@import './style/leaflet-map.css';

@import './style/img-cropper.css';

/* tour items */
@import './style/rating.css';
@import './style/tour-fact.css';

/* package */
@import './style/package.css';

@import './style/notification.css';

@import './style/login-box.css';
@import './style/cookie.css';
@import './style/shariff.css';
@import './style/consent-overlay.css';

/* demi custom */
@import './style/demi-infrastructure-custom.css';
@import './style/demi-events-custom.css';

/*Chatbot */
@import './style/chatbot/chatbot.css';
@import './style/chatbot/acco-teaser.css';
@import './style/chatbot/acco-gallery.css';
@import './style/chatbot/room-occupancy.css';

/* Travelshop*/
@import './style/travelshop.css';

/*utilities & helpers*/
@import './style/row-helpers.css';
@import './style/col-helpers.css';
@import './style/list-helpers.css';
@import './style/oocss.css';
@import './style/variables.css';

@import './style/matomo-exclude.css';
html.piwikHeatmap .hero-img--full-height { max-height: 900px; }