.horizontal-teaser__body {
    padding: 10px 10px 30px;

    @media screen and (min-width: 768px) {
        padding: 10px 30px 10px 60px;
    }
}
.horizontal-teaser__body__category {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
}
.horizontal-teaser__body__category:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80px;
    height: 3px;
    background: var(--color-dark);
}
.horizontal-teaser__body__category li {
    position: relative;
}
.horizontal-teaser__body__category li + li {
    padding-left: 5px;
}
.horizontal-teaser__body__category li + li:before {
    content: '·';
    position: absolute;
    left: -5px;
    top: 0;
    font-size: 20px;
}
.horizontal-teaser__img{
    position: relative;
    overflow: hidden;
}
.horizontal-teaser__img:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent #fff transparent;
    z-index: 1;

    @media screen and (min-width: 768px) {
        bottom: auto;
        left: auto;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        border-width: 20px 20px 20px 0;
        border-color: transparent #fff transparent transparent;
    }
}
.bg-grey-light .horizontal-teaser__img:after {
    border-color: transparent transparent var(--color-grey-light) transparent;
    @media screen and (min-width: 768px) {
        border-color: transparent var(--color-grey-light) transparent transparent;
    }
}
.horizontal-teaser__img__badge {
    position: absolute;
    bottom:0;
    left: 0;
    padding: 10px 15px;
    font-size: 16px;
    line-height: calc(20/16);
}

.horizontal-teaser__body__bottom {
    margin-top: 10px;

    @media screen and (min-width: 768px) {
        margin-top: 25px;
    }
}

.horizontal-teaser__body__hl {
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
        margin-bottom: 20px;
    }
}

/* img right */
@media screen and (min-width: 768px) {
    .horizontal-teaser--img-right .horizontal-teaser__body{
        padding-left: 0;
    }
    .horizontal-teaser--img-right .horizontal-teaser__img:after {
        right:auto;
        left: 0;
        border-width: 20px 0 20px 20px;
        border-color: transparent transparent transparent #fff;
    }
    .bg-grey-light .horizontal-teaser--img-right .horizontal-teaser__img:after {
        border-color: transparent transparent transparent var(--color-grey-light);
    }
}

/* small teaser */
.horizontal-teaser--sm .horizontal-teaser__body {
    padding: 10px;
    font-size: 16px;

    @media screen and (min-width: 768px) {
        padding: 0 0 10px 15px;
    }
    @media screen and (min-width: 1200px) {
        padding: 0 0 10px 30px;
        font-size: 18px;
    }
}
.horizontal-teaser--sm .horizontal-teaser__body__category {
    padding-bottom: 0;
    margin-bottom: 5px;

    @media screen and (min-width: 768px) {
        padding-top: 15px;
    }
}
.horizontal-teaser--sm .horizontal-teaser__body__category:after {
    @media screen and (min-width: 768px) {
        bottom: auto;
        top: 0;
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.horizontal-teaser--sm .horizontal-teaser__body__hl {
    font-size: 20px;
    line-height: calc(30/22);
    margin-bottom: 8px;

    @media screen and (min-width: 1200px) {
        font-size: 22px;
        margin-bottom: 15px;
    }
}
.horizontal-teaser:hover .horizontal-teaser__body__hl>a {
    color: var(--color-primary);
}

/* closed/disabled state */
.horizontal-teaser--closed .horizontal-teaser__img .embed-responsive-item,
.horizontal-teaser--disabled .horizontal-teaser__img .embed-responsive-item {
    filter: grayscale(100%);
}

/* logo */
.horizontal-teaser__img__logo {
    position: absolute;
    right: 10px;
    top: 10px;

    @media screen and (min-width: 768px) {
        right: 32px;
        top: 32px;
    }
}

/* slider */

.horizontal-teaser__slider{
    position: relative;
}
.horizontal-teaser__slider .slick-prev {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    border-right: 1px solid white;
}

.horizontal-teaser__slider .slick-next {
    left: calc(50% - 2.5rem);
    position: absolute;
    bottom: 0;
    z-index: 2;
    border-left: 1px solid white;
}

.horizontal-teaser__slider .slick-prev,
.horizontal-teaser__slider .slick-next {
    height:  2.5rem;
    width: 2.5rem;
    padding: 0.75rem 0.5rem;
}

.horizontal-teaser__slider .horizontal-teaser__slider__description{
    height: 2.5rem;
    color: white;
    background-color: var(--color-primary);
    padding: 0.625rem 2.75rem;
    width: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    font-size: 1.25rem;
    line-height: 1;
    @media screen and (max-width:1450px){
        font-size: 1.125rem;
        padding: 0.75rem 2.75rem;
    }
    @media screen and (max-width:1340px){
        font-size: 1rem;
    }
    @media screen and (max-width:1230px){
        padding: 0.2rem 2.75rem;
    }
}

.horizontal-teaser__body__buttons a {
    display: inline-block;
@media screen and (max-width: 767px) {
    padding: 10px 0;
    display: block;
    max-width: 250px;
}
}

.horizontal-teaser__slider .horizontal-teaser__body__text{
    font-size: 1.0625rem;
}

@media screen and (min-width:768px){
    .horizontal-teaser__slider.horizontal-teaser--img-right .horizontal-teaser__body{
        padding-left: 60px !important;
    }
}

@media screen and (max-width:1199px){
    .horizontal-teaser__slider__description{
        padding-top: 0.375rem;
    }
}

@media screen and (max-width:767px){
    .horizontal-teaser__slider{
        margin-bottom:2rem;
    }
    .horizontal-teaser__slider__description{
        display: none;
    }
    .horizontal-teaser__slider .slick-next {
        left: 3.125rem;
        border: none;
        top: calc(10rem/16);
    }
    .horizontal-teaser__slider .slick-prev {
        left: 0;
        top: calc(10rem/16);
    }
    /*
    .horizontal-teaser__slider--right .slick-next {
        right: 0;
        left: auto;
    }*/
    .horizontal-teaser__slider .horizontal-teaser__body {
        padding-bottom: 3rem;
    }
}

/* slider right */

.horizontal-teaser__slider--right .slick-prev {
    right: calc(50% - 2.5rem);
    left: auto;
}

.horizontal-teaser__slider--right .slick-next {
    right: 0;
    left: auto;
}

.horizontal-teaser__slider--right .horizontal-teaser__slider__description{
    left: auto;
    right: 0;
}

/* adjustments for overflowing badges on slider */

.horizontal-teaser__slider .horizontal-teaser__img{
    overflow: visible;
}

.horizontal-teaser__slider .slick-track {
    padding-top: 10px;
}

.horizontal-teaser__slider .slick-list {
    margin: 0 0 -10px 0;
}

@media screen and (max-width:767px){

    .horizontal-teaser__slider .slick-slide{
        padding: 0 10px;
    }

    .horizontal-teaser__slider .slick-prev {
        margin-left: 10px;
    }

}
.horizontal-teaser__slider .slick-disabled {
    opacity: .5;
    pointer-events: none;
}