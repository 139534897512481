.form-control-label {
    position: absolute;
    font-size: 18px;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    margin: 0;
    line-height: 1;
    pointer-events: none;
    z-index: 1;
    white-space: nowrap;

    @media screen and (min-width: 1200px) {
        font-size: 20px;
    }
}

.form-control:focus ~ .form-control-label,
.select ~ .form-control-label,
.form-control.has-value ~ .form-control-label {
    transform: translateY(-95%) scale(.6);
}

/* textarea control label */
textarea + .form-control-label,
textarea + .parsley-errors-list + .form-control-label {
    top: 25px;
}

/* form control label relative */
.form-control-label-relative {
    font-size: 20px;
    line-height:1;
    margin-bottom: 15px;
}
