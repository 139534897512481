.intro-text {
    padding-top: 20px;
    position: relative;
    padding-bottom: 35px;

    @media screen and (min-width: 768px) {
        margin-top: 10px;
        padding: 0 0 20px 110px;
        max-width: 1290px;
        column-count: 2;
        column-gap: 70px;
    }
    @media screen and (min-width: 1400px) {
        padding: 0 0 40px 175px;
        column-gap: 110px;
    }
}
.intro-text:before {
    content: '';
    width: 80px;
    height: 3px;
    background-color: var(--color-dark);
    position: absolute;
    top: 6px;
    left: 0;
}

.intro-text--expand {
    position: relative;
    padding-bottom: 35px;

    @media screen and (min-width: 768px) {
        margin-top: 10px;
        padding: 0 0 20px 110px;
        max-width: 1290px;
        column-gap: 70px;
    }
    @media screen and (min-width: 1400px) {
        padding: 0 0 40px 175px;
        column-gap: 110px;
    }
}

.intro-text__read-more {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
}
.intro-text__read-more.collapsed {
    opacity: 1;
}