.package {
    background: transparent;
    border: 0;
}
.package + .package {
    margin-top: 10px;
}
.package__head {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    background-color: #fff;
}
.package__head__title {
    padding: 15px;
    font-size: 16px;

    @media screen and (min-width: 1200px) {
        padding: 16px 20px;
        font-size: 18px;
    }
}
.package__head__select {
    border-top: 1px solid var(--color-grey-light);

    @media screen and (min-width: 768px) {
        border-top: 0;
        border-left: 1px solid var(--color-grey-light);
    }
}
.package__head__select > select {
    padding: 16px;
    font-size: 15px;
    min-height: 100%;
    cursor: pointer;

    @media screen and (min-width: 1200px) {
        font-size: 18px;
        padding: 18px;
    }
}
.package__head__select:before {
    padding: 0 18px 0 5px;
}
.package__head__counter__control {
    border: 0;
    width: 33%;
    min-height: 60px;
    height: 100%;
    float: left;
    border-radius: 0;

    @media screen and (min-width: 768px) {
        width: 60px;
    }
}
.package__head__counter__control + .package__head__counter__control {
    margin-left: 1px;
}
.package__head__counter__input {
    background: var(--color-grey-light);
    line-height: 1;
    border: 0;
    color: var(--color-dark);
    font-size: 22px;
}
.package__body {
    padding: 10px 0 30px 0;
}
.package__body__form + .package__body__form{
    margin-top: 20px;
    padding-top: 30px;
    border-top:1px solid var(--color-dark);
}