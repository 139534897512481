.lg-backdrop.in {
    opacity: .8;
}
.lg-actions .lg-next,
.lg-actions .lg-prev {
    background: var(--color-primary);
    border: 0;
    border-radius: 0;
    color: #fff;
    width: 50px;
    height: 50px;
    font-size: 18px;

    @media screen and (min-width: 1200px){
        width: 70px;
        height: 70px;
        font-size: 22px;
    }
}
.lg-actions .lg-next:before,
.lg-actions .lg-prev:after {
    content: none;
}
.lg-sub-html, .lg-toolbar {
    background: transparent;
}
.lg-toolbar {
    background: #fff;
}
#lg-counter {
    color: var(--color-dark);
}
.lg-toolbar .lg-icon {
    background: var(--color-primary);
    color: #fff;
}
.lg-outer .lg-video-cont {
    max-width: 1500px !important; /* overwrite inline style */
}