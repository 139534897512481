.ribbon-badge {
    position: absolute;
    z-index: 1;
    top: 16px;
    left: -28px;
    width: 150px;
    transform: rotate(-30deg);
    padding: 3px 0;
    text-align: center;
    font-size: 10px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);

    @media screen and (min-width: 768px) {
        width: 260px;
        top: 23px;
        left: -52px;
        padding: 10px 0 9px;
        font-size: 18px;
    }
    @media screen and (min-width: 1200px) {
        width: 390px;
        top: 45px;
        left: -75px;
        padding: 14px 0 13px;
        font-size: 20px;
    }
}