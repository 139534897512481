/*text helper*/
.text-underline, a.text-underline:hover { text-decoration: underline; }
.text-no-deco {
    text-decoration: none;
}
.text-transform-none {
    text-transform: none;
}

.no-margin.no-margin { margin: 0; }
.mb0 { margin-bottom: 0 }
.mb5 { margin-bottom: 5px; }
.mb10 { margin-bottom: 10px; }
.mb15 { margin-bottom: 15px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }

.mt5 { margin-top: 5px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }
.mt20-negative { margin-top: -20px; }

.no-padding { padding: 0; }

/*background colors*/
.bg-primary {background: var(--color-primary); color: #fff;}
.bg-white { background: #fff; color: var(--color-dark)}
.bg-dark { background: var(--color-dark); color: #fff;}
.bg-grey-light { background: var(--color-grey-light); }
.bg-grey { background: var(--color-grey); }

/*text colors*/
.text-primary,
.text-primary-hover:hover {color: var(--color-primary);}
.text-white { color: #fff; }
.text-black { color: #000; }
.text-default { color: var(--color-default); }
.text-grey { color: var(--color-grey); }

/*helper*/
.isClickable {cursor: pointer;}
.d-ib {display: inline-block;}
.d-b {display: block;}

a.skiplink {
    position: fixed;
    left: -10000px;
    top: -10000px;
    z-index: 1000;
    font-size: 14px;
    padding: 3px;
}
a.skiplink:focus {
    left: 0;
    top: 0;
    outline: none;
    background-color: var(--color-primary);
    color: #fff;
}
.link-arrow {
    font-size: 10px;
    margin-right: 2px;

    @media screen and (min-width: 768px) {
        font-size: 14px;
        margin-right: 4px;
    }
}
.hide-focus:focus {
    outline: none;
}

.circle-icon {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border:3px solid #fff;
    font-size: 25px;
    position: relative;

    @media screen and (min-width: 768px) {
        width: 80px;
        height: 80px;
        font-size: 37px;
    }
}
.circle-icon:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.list-column-2{
    column-count: 2;
}

.todo {
    background: orange;
    padding: 0 5px;
}

/* font size helpers */

.fs-20 {
    font-size: calc(20rem/16) !important;
}

.image-holder {
    height: calc(90rem / 16);
    width: calc(90rem / 16);
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 calc(10rem / 16) calc(30rem / 16) 0 rgba(0,0,0,0.2);
    padding: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        height: calc(258rem / 16);
        width: calc(258rem / 16);
        padding: calc(40rem / 16);
    }
}
.image-holder__img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}
