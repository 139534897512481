.percent-circle {
    position: relative;
    width: 212px;
    height: 212px;
    margin-bottom: 20px;

    @media screen and (min-width: 1200px) {
        width: 252px;
        height: 252px;
    }

    @media screen and (min-width: 1450px) {
        width: 382px;
        height: 382px;
    }
}
.percent-circle__text {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 16px;
    line-height: calc(35/22);
    transform: translateY(-50%) translateX(-50%) scale(0);
    opacity: 0;
    transition-delay: 200ms;
    transform-origin: center;
    width: 100%;

    @media screen and (min-width: 1200px) {
        font-size: 22px;
    }
}
.percent-circle__text__count{
    font-size: 70px;
    line-height: calc(85/100);
    margin-bottom: 5px;

    @media screen and (min-width: 768px) {
        font-size: 60px;
    }
    @media screen and (min-width: 1450px) {
        font-size: 100px;
    }
}
.is-animated + .percent-circle__text {
    transition: transform 350ms ease-in-out, opacity 350ms ease-in-out;
    transform: translateY(-50%) translateX(-50%) scale(1);
    opacity: 1;
}
.percent-circle {
    position: relative;
}
.percent-circle:before {
    content: '';
    position: absolute;
    top: 7px;
    bottom: 7px;
    left: 7px;
    right: 7px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 100%;

    @media screen and (min-width: 1200px) {
        top: 8px;
        bottom: 8px;
        left: 8px;
        right: 8px;
    }

    @media screen and (min-width: 1450px) {
        top: 12px;
        bottom: 12px;
        left: 12px;
        right: 12px;
    }
}
.percent-circle svg {
    width: 100%;
    height: 100%;
    position: relative;
}
.percent-circle__circle {
    stroke: rgba(0, 0, 0, .4);
    stroke-width: 2;
}
.percent-circle__circle--percent {
    stroke: var(--color-primary);
    transition: stroke-dasharray 1400ms ease-in-out;
}