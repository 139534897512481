.state-circle {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 100%;
    position: relative;
    vertical-align: -0.2em;
    margin-right: 15px;
}
.state-circle__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: 8px;
}
.state-circle.is-open {
    background-color: var(--color-primary);
}
.state-circle.is-closed {
    background-color: var(--color-danger);
}
.state-circle.is-info {
    background-color: var(--color-dark);
    color: #fff;
}
.state-circle.is-info .state-circle__icon:before {
    content: "i";
    font-size: 13px;
    font-family: var(--font-bold-family);
}
.legende {
    padding: 30px 20px 0;

    @media screen and (min-width: 768px) {
        font-size: 0.8em;
    }

    @media screen and (max-width: 767px) {
        padding-top: 15px;
    }
}