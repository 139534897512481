.input-box{
    position: relative;
    text-align: left;
    font-size: 16px;

    @media screen and (min-width: 768px) {
        text-align: center;
    }

    @media screen and (min-width: 1200px) {
        font-size: 18px;
    }
    @media screen and (min-width: 1400px) {
        font-size: 20px;
    }
}
.input-box__dropdown-toggle__label {
    font-size: 16px;
    line-height: calc(30/20);

    @media screen and (min-width: 1200px) {
        font-size: 18px;
    }
    @media screen and (min-width: 1400px) {
        font-size: 20px;
    }
}
.input-box__dropdown-toggle {
    cursor: pointer;
    font-family:var(--font-regular-family);
    font-weight: 400;
    background: transparent;
    border: none;
    font-size: 32px;
    display: block;
    width: 100%;
    padding: 0 8px 10px;
    line-height: 1;
    height: 100px;

    @media screen and (min-width: 1400px) {
        font-size: 40px;
        padding: 0 15px 10px;
        height: 125px;
    }
}
.input-box__dropdown-toggle:after {
    content: none;
}
.dropdown-select__btn-text{
    position: relative;
}
.dropdown-select__btn-text:before {
    content: var(--icon-arrow-down);
    font-family:iconfont;
    line-height: 1;
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 12px;
    transform: translateY(-50%);
}
.input-box__dropdown {
    background: #f0f0f0;
    border-top:1px solid #fff;
    height: 260px;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling:touch;
}

.input-box__dropdown .dropdown-item {
    padding: 0;
}
.input-box__dropdown .dropdown-item > a {
    font-size: 28px;
    display: block;
    padding: 5px 15px;
}

/* date input box */
.input-box--date {
    width: 100%;
    border: 0;
    cursor: pointer;
    padding: 18px 22px 12px;
    line-height: 1;
    height: 55px;

    @media screen and (min-width: 768px) {
        height: 100px;
        padding: 12px 8px;
    }
    @media screen and (min-width: 1400px) {
        height: 125px;
        padding: 17px 15px;
    }
}
.input-box__label {
    margin-bottom: 5px;
}
.input-box__date {
    @media screen and (min-width: 768px) {
        display: block;
        font-size: 32px;
        margin-bottom: 5px;
        position: relative;
    }
    @media screen and (min-width: 1200px) {
        font-size: 40px;
    }
}
.input-box__date:before {
    content: var(--icon-arrow-down);
    font-family:iconfont;
    line-height: 1;
    position: absolute;
    top: 50%;
    right: 15px;
    font-size: 10px;
    transform: translateY(-50%);

    @media screen and (min-width: 768px){
        right: 0;
        font-size: 12px;
    }
}
.input-box__datepicker {
    visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}