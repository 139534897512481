.list-inline-15 .list-inline-item:not(:last-child) {
    margin-right: 15px;
}
.list-inline-30 .list-inline-item:not(:last-child) {
    margin-right: 30px;
}
.list-inline-35 .list-inline-item:not(:last-child) {
    margin-right: 35px;
}
.list-inline--align-middle li {
    vertical-align: middle;
}

@media screen and (min-width: 768px) {
    .flex-list {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .flex-list > li {
        display: flex;
        flex: 1 1 auto;
    }
}

/* disable list inline on mobile */
@media screen and (max-width: 767px) {
    .list-inline-disableOnXs.list-inline-disableOnXs .list-inline-item {
        margin: 0;
        display: block;
        text-align: left;
        margin-bottom: 15px;
    }
}