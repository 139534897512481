/* radio button */
.radio-btn-tab-group {
    @media screen and (min-width: 768px) {
        font-size: 0;
    }
}
.radio-btn-tab-group li {
    @media screen and (min-width: 768px) {
        display: inline-block;
        font-size: 20px;
    }
}
.radio-btn {
    cursor: pointer;
}
.radio-btn:hover .radio-btn__btn {
    color: var(--color-default);
}
.radio-btn__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.radio-btn__btn {
    border: 2px solid var(--color-grey-light);
    color: var(--color-grey);
    cursor: pointer;
    font-size: 14px;
    line-height: 1.5;
    padding: 6px 10px 5px;
    position: relative;
    text-align: center;

    @media screen and (min-width: 768px){
        padding: 8px 15px 7px;
        font-size: 18px;
    }
}
.radio-btn__input:checked ~ .radio-btn__btn {
    background: var(--color-grey-light);
    color: var(--color-default);
}
@media screen and (min-width: 768px){
    .radio-btn-tab-group .radio-btn__input:checked ~ .radio-btn__btn:after {
        content: "";
        border-style: solid;
        border-width: 15px 14px 0;
        border-color: var(--color-grey-light) transparent transparent;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateY(100%) translateX(-50%);
    }
}

/* large radio button */
.radio-btn--lg .radio-btn__btn {
    font-size: 20px;
    padding: 14px;

    @media screen and (min-width: 768px){
        padding: 7px 16px 9px;
    }
}

.has-error .radio-btn__btn {
    color: var(--color-danger);
}
.has-error .radio-btn__btn:before {
    content: '';
    background: var(--color-danger);
    position: absolute;
    left: -2px;
    top: -2px;
    width: 4px;
    bottom: -2px;
}