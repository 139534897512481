.main-footer {
    padding: 25px 0;
    background-color: var(--color-grey-light);
    font-size: 14px;
    line-height: calc(24 / 14);

    @media screen and (min-width: 768px) {
        padding: 55px 0 40px;
        font-size: 16px;
        line-height: calc(30 / 20);
    }
}
.main-footer__link-with-icon > .icon {
    font-size: 22px;
    vertical-align: -.2em;
    margin-right: 0.3em;
}
.main-footer__bottom {
    margin-top: 25px;

    @media screen and (min-width: 768px) {
        margin-top: 40px;
    }
}
.pre-footer-slide {
    font-size: 24px;
    padding: 15px 0;
    background-color: var(--color-grey-light);
    border-bottom: 2px solid #fff;

    @media screen and (min-width: 768px) {
        font-size: 34px;
        padding: 35px 0;
    }
}
.pre-footer-slide__social-list li:not(:last-child) {
    margin-right: 25px;

    @media screen and (min-width: 768px) {
        margin-right: 80px;
    }
}

.main-footer .col:first-child .main-footer__block {
    padding-left: 0;
}
.main-footer .col:last-child .main-footer__block {
    padding-right: 0;
}
.main-footer__block__text {
    margin-bottom: 20px;
}
.main-footer__block__partner {
    max-width: 230px;
    height: auto;
    opacity: 0.4;
    will-change: opacity;
}
.main-footer__block__partner {
    opacity: 0.4;
    transition: opacity 250ms ease-in-out;
}
.main-footer__block__partner > img{
    max-width: 300px;
    height: auto;
}
.main-footer__block__partner:hover {
    opacity: 1;
}
.main-footer .site-by-elements {
    max-width: 155px;
    height: auto;
}