.travelshop__teaser {
    height: 100%;
}

.travelshop__hero-img{
    position: absolute;
    right: 0;
    top: 0;
    max-width: 60%;
    height: auto;
    pointer-events: none;
}

.travelshop__title-area{
    position: relative;
}

.travelshop__hero-title{
    font-size: 110px;
    text-transform: uppercase;
    font-family: var(--font-bold-family);
    margin-left: -7px;
    margin-bottom: 1rem;
    line-height: 1.2;
}

.travelshop__hero-text{
    margin-top: 5rem;

    @media screen and (min-width: 1600px){
        margin-top: 10rem;
    }
}

.travelshop__book-btn{
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
}

.travelshop__select-btn{
    text-transform: none;
    font-family: var(--font-bold-family);
    position: relative;
}

.travelshop__btn--active:after {
    content:'';
    width: 0;
    height: 0;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    vertical-align: center;
    border-style: solid;
    border-width: 20px 17.5px 0 17.5px;
    border-color: #171717 transparent transparent transparent;
}

.travelshop__teaser {
    background-color: white;
    width: 100%;
    position: relative;
    display:flex;
    flex-direction: column;
}

.travelshop__teaser--shadow {
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.2);
}

.travelshop__teaser-content{
    padding: 1.5rem 1.5rem 4rem 1.5rem;
    height: auto;
    flex-grow : 1;
    position: relative;
}

.travelshop__teaser-content > ul > li{
    list-style-type: square;
    color: var(--color-primary);
    padding: 0.25rem 0;
}

.travelshop__teaser-content > ul > li > span{
    color: var(--color-default);
}

.travelshop__teaser-headline {
    font-family: var(--font-bold-family);
    font-size: 24px;
    line-height: 1.25;
    margin-bottom: 10px;
}

.travelshop__teaser__badge{
    position: absolute;
    display: inline-block;
    top: -10px;
    right: -10px;
    width: 100%;
    height: 150px;
    overflow: hidden;
    pointer-events: none;
    z-index: 15;
}

.travelshop__teaser__badge--inner{
    display: block;
    position: absolute;
    top: 30px;
    right: -75px;
    width: 250px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    pointer-events: auto;
}

.travelshop__teaser__badge--yellow > .travelshop__teaser__badge--inner{
    background-color: #FFCB00;
}

.travelshop__teaser__badge--yellow > .travelshop-badge__overlap-top,
.travelshop__teaser__badge--yellow > .travelshop-badge__overlap-right{
    background-color: #C9A001;
}


.travelshop__teaser__badge--pink > .travelshop__teaser__badge--inner{
    background-color: #CC0D62;
    color: white;
}

.travelshop__teaser__badge--pink > .travelshop-badge__overlap-top,
.travelshop__teaser__badge--pink > .travelshop-badge__overlap-right{
    background-color: #A3054A;
}

.travelshop-badge__overlap-top{
    width: 10px;
    height: 10px;
    top: 0;
    right:118px;
    position: absolute;
}

.travelshop-badge__overlap-right{
    width: 10px;
    height: 10px;
    top: 118px;
    right: 0;
    position: absolute;
}

.travelshop__teaser__badge__text{
    font-family: var(--font-bold-family);
    font-size: 14px;
    max-width: 150px;
    line-height: 1.1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

@media screen and (max-width: 767px){
    .travelshop__hero-title{
        font-size: 50px;
        margin-left: -2px;
        line-height: 1;
        word-break: break-word;
    }
    .travelshop__hero-img{
        max-width: 90%;
    }

    .travelshop__hero-text{
        margin-left: 0;
        margin-top: 16rem;
    }

    .travelshop__hero-text > .wysiwyg {
        display: none;
    }

    .travelshop__select-btn{
        padding: 12px 30px 11px;
        font-size: 18px;
    }

    .travelshop__teaser{
        margin-top: 1rem;
    }
}
.travelshop__title-area__title {
    font-size: calc(26rem / 16);


    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
    }
}