.navbar-main__dropdown {
    text-align: left;
    position: relative;
    background: var(--color-grey-light);
    display: block;
    font-size: 16px;

    @media screen and (min-width: 768px) {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        padding: 10px 20px 10px 128px;
        border-top:1px solid var(--color-grey-light);
        visibility: hidden;
        opacity: 0;
        transition: opacity 250ms ease-in-out;
        background: #fff;
        color: var(--color-dark);
        box-shadow: 0 2px 4px rgba(0,0,0,.2);
        overflow: hidden;
    }

    @media screen and (min-width: 1200px) {
        padding: 10px 20px 10px 160px;
    }

    @media screen and (min-width: 1400px) {
        padding: 50px 50px 50px 240px;
    }
}
.navbar-nav li:hover > .navbar-main__dropdown,
.navbar-main__dropdown:hover,
.navbar li.is-open > .navbar-main__dropdown {
    visibility: visible;
    opacity: 1;
}
.navbar-main__dropdown__container {
    width: 100%;
    max-width: 1195px;
}
@media screen and (min-width: 786px) {
    .navbar-main__dropdown__hl {
        margin-bottom: 10px;
        font-family:var(--font-bold-family);
        font-weight: 400;
        text-transform: uppercase;
    }
}
.navbar-main__dropdown__list{
    position: relative;
}
.navbar-main__dropdown__list li:first-child {
    @media screen and (min-width: 768px) {
        border-top:1px solid var(--color-grey-light);
    }
}
.navbar-main__dropdown__list li {
    border-top:1px solid #fff;

    @media screen and (min-width: 768px) {
        border: 0;
        border-bottom:1px solid var(--color-grey-light);
    }
}
.navbar-main__dropdown li.active > a,
.navbar-main__dropdown__list a:hover {
    color: var(--color-primary);
}

/* navbar dropdown subnav */
.navbar-main__dropdown__list li a {
    position: relative;

    @media screen and (min-width: 768px) {
        display: block;
        padding: 12px 0;
    }
}
.subnav-toggle{
    position: absolute;
    right:0;
    top: 0;
    bottom: 0;
    padding: 22px 15px 22px 35px;
    font-size: 10px;
    cursor: pointer;
}
.subnav-toggle:before {
    transform: rotate(45deg);
    transition: transform 250ms ease-in-out;
    display: block;
}
@media screen and (min-width: 768px){
    .subnav-toggle:hover {
        color: var(--color-primary);
    }
}
.navbar-main__dropdown__list--subnav {
    display: none;
    position: relative;
    background: var(--color-grey-light);

    @media screen and (min-width: 768px) {
        display: block;
        background: #fff;
    }
}
li.is-open > .navbar-main__dropdown__list--subnav {
    display: block;
}

.navbar-main__dropdown .level-back {
    padding: 12px 0;
    display: block;
    text-transform: uppercase;
}
.level-back__icon  {
    font-size: 14px;
    margin-right: 9px;
}

/* dropdown close button */
.navbar-main__dropdown__close {
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 16px;
    z-index: 1;

    @media screen and (min-width: 1200px) {
        font-size: 20px;
        right: 20px;
        top: 20px;
    }
}

/* small dropdowns */
.navbar-main .has-small-dropdown {
    position: relative;
}
.navbar-main__dropdown.navbar-main__dropdown--md {
    padding: 0;
    width: 700px;
    right: 0;
    left: auto;
    font-size: 16px;

    @media screen and (min-width: 1200px) {
        width: 860px;
    }
}

.navbar-main__dropdown.navbar-main__dropdown--sm {
    padding: 0;
    width: 300px;
    right: 0;
    left: auto;
    font-size: 16px;
}

.navbar-main__dropdown__col {
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: 1200px) {
        padding: 30px 40px;
    }
}
[class*='col-'] + [class*='col-'] .navbar-main__dropdown__col {
    border-left:1px solid var(--color-grey-light);
}

.navbar-main__dropdown__col--bottom {
    border-left: 0;
    border-top:1px solid var(--color-grey-light);
}
