.map-infobox {
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}
.map-infobox__head {
    position: relative;
}
.map-infobox__head__close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    font-size: 20px;
    color: #fff;
    padding: 10px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    background: transparent;
    border: 0;
    cursor: pointer;
}
.map-infobox__head:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent #ffffff transparent;
}
.map-infobox__body {
    padding: 20px;
    font-size: 15px;
    line-height: calc(23/15);
}
.map-infobox__body__hl {
    font-size: 18px;
    line-height: calc(30/18);
    margin-bottom: 5px;
}
.interactive-map .infoBox > img {
    display: none;
}
.interactive-map .infoBox {
    padding-top: 20px;
    transform: translateX(-50%);
}