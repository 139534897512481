.countdown {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 90%;
    font-size: 16px;
    line-height: calc(35/24);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

    @media screen and (min-width: 768px) {
        width: 45%;
        font-size: 20px;
        transform: translateY(-60%) translateX(-50%);
    }

    @media screen and (min-width: 1200px) {
        font-size: 24px;
        max-width: 590px;
    }
}
.countdown:before,
.countdown:after {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    bottom: 3px;
    background: #fff;
    width: 4px;
    z-index: 1;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.countdown:after {
    left: auto;
    right: 0;
}
.countdown__text {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.countdown__text:before,
.countdown__text:after {
    background-color: #fff;
    content: "";
    flex: 1 0 auto;
    height: 4px;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    margin-right: 25px;
}
.countdown__text:after {
    margin-right: 0;
    margin-left: 25px;
}
.countdown__text__centered {
    transform: translateY(-40%);
    max-width: 60%;
}
.countdown__text--bottom {
    transform: translateY(calc(100% - 4px));
    position: relative;
    z-index: 1;
}
.countdown__count {
    padding: 5px 20px;

    @media screen and (min-width: 768px) {
        padding: 10px 40px;
    }

    @media screen and (min-width: 1200px) {
        padding: 25px 40px 20px 40px;
    }
}
.countdown__count__val {
    font-size: 55px;
    line-height: calc(85/110);
    margin-bottom: 5px;

    @media screen and (min-width: 768px) {
        font-size: 80px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 110px;
        margin-bottom: 10px;
    }
}