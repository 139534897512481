.watchList-button {
    font-size: 25px;
    transition: color 250ms ease-in-out;
}
.watchList-button:hover,
.is-added.watchList-button {
    color: var(--color-primary);
}

/*add animation*/
.watchList-button:after {
    content: var(--icon-merkliste);
    font-family:iconfont;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0);
    font-size: 25px;
    color: var(--color-primary);
    transform-origin: center center;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
}
.is-added.watchList-button:after {
    transform: translateY(-50%) translateX(-50%) scale(3);
    opacity: 0;
}

.watchList-info.has-items,
.watchList-info.has-items:active {
    color: var(--color-primary);
}
.watchList-info__count {
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-52%) translateX(-50%);
    color: #fff;
}

/* text button */
.watchList-button--text {
    font-size: 16px;
}
.watchList-button--text:hover,
.is-added.watchList-button--text {
    color: #fff;
}
.watchList-button--text:hover .watchList-button__icon,
.is-added.watchList-button--text .watchList-button__icon {
    color: var(--color-primary);
}
.watchList-button.is-added .watchList-button__add, .watchList-button__remove {
    display: none;
}
.watchList-button.is-added .watchList-button__remove {
    display: inline-block;
}
.watchList-button--text:after {
    content: none;
}
.watchList-button--text .watchList-button__icon {
    vertical-align: -.18em;
    left: -5px;
    position: relative;
}

.watchList-info__icon:after {
    content: var(--icon-merkliste);
    font-family:iconfont;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0);
    font-size: 35px;
    color: var(--color-primary);
    transform-origin: center center;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
}
.is-animated .watchList-info__icon:after {
    animation: itemAdded 250ms ease-in-out;
}
@keyframes itemAdded {
    from {
        transform: translateY(-50%) translateX(-50%) scale(0);
        opacity: 1;
    }
    to {
        transform: translateY(-50%) translateX(-50%) scale(3);
        opacity: 0;
    }
}