.google-map {
    position: relative;
}
/*marker*/
.google-map__marker>.icon {
    top: 1px;
    position: relative;
    font-size: 54px;
    transition: color 250ms ease-in-out;
    color: var(--color-default);
}
.google-map__marker-wrapper.is-active {
    z-index:1000 !important;
}
.google-map__marker-wrapper.is-active .icon {
    color: #000;
}

/* search field */
.google-map__search {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

    @media screen and (min-width: 768px) {
        right: auto;
        bottom: 30px;
        left: 30px;
        max-width: 100%;
        width: 355px;
    }
}
.google-map__search__input {
    width: 100%;
}
.google-map__search__btn {
    position: relative;
    padding: 10px 25px;
}
.google-map__search__btn .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: 20px;
}

.google-map__search--top {
    top: 10px;
    bottom: auto;

    @media screen and (min-width: 768px){
        top: 30px;
        bottom: auto;
    }
}