.nav-tabs {
    border: 0;
    margin-bottom: 30px;
}
.nav-tabs .nav-link,
.nav-tabs .nav-link:hover {
    border: 2px solid var(--color-grey-light);
    color: var(--color-grey);
}
.nav-tabs .nav-link:hover {
    color: var(--color-default);
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background: var(--color-grey-light);
    color: var(--color-default);
    border: 2px solid var(--color-grey-light);
}
.nav-tabs .nav-link {
    position: relative;
    font-size: 16px;
    line-height:1.2;
    border-radius: 0;
    padding: 5px 8px;

    @media screen and (min-width: 768px) {
        font-size: 20px;
        line-height: 1.5;
        padding: 8px 16px;
    }
}
.nav-tabs .nav-link.active:after {
    content: '';
    border-style: solid;
    border-width: 15px 14px 0 14px;
    border-color: var(--color-grey-light) transparent transparent transparent;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateY(100%) translateX(-50%);
}
.nav-tabs .nav-item + .nav-item .nav-link {
    border-left: 0;
}

/* dark nav tabs */
.nav-tabs--dark .nav-link,
.nav-tabs--dark .nav-link:hover {
    border: 1px solid var(--color-dark);
    color: var(--color-dark);
}
.nav-tabs--dark .nav-link:hover {
    color: var(--color-primary);
}
.nav-tabs--dark .nav-item.show .nav-link,
.nav-tabs--dark .nav-link.active {
    background: var(--color-dark);
    color: #fff;
    border: 1px solid var(--color-dark);
}
.nav-tabs--dark .nav-link.active:after {
    border-color: var(--color-dark) transparent transparent transparent;
}

/* large nav tabs */
.nav-tabs--lg {
    @media screen and (min-width: 768px) {
        margin-bottom: 60px;
    }
}
.nav-tabs--lg .nav-link {
    @media screen and (min-width: 768px) {
        font-size: 22px;
        line-height:calc(28/22);
        padding: 15px;
    }
}

.tab-title__list .nav-tabs .nav-link {
    @media screen and (max-width: 767px) {
        min-height: 50px;
    }
}