.img-slide {
    background-size: cover;
    background-position: center;
    padding: 25px 0;
    position: relative;

    @media screen and (min-width: 768px) {
        padding: 55px 0;
    }
}
.img-slide:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(180deg, #448332 0%, #335102 100%);
    opacity: .86;
}
.img-slide__content {
    position: relative;
}
.img-slide__content__hl {
    margin-bottom: 35px;
}
.img-slide__content__hl-sm {
    font-size: 22px;
    line-height: calc(50/30);
    text-transform: none;

    @media screen and (min-width: 768px) {
        font-size: 30px;
    }
}
.img-slide__content__hl-lg {
    font-size: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(60rem / 16);
    }
}
.img-slide__content__subtitle {
    font-size: 30px;
    line-height: calc(45/40);

    @media screen and (min-width: 768px) {
        font-size: 40px;
    }
}
.img-slide__content__col{
    margin-top: 30px;

    @media screen and (min-width: 768px) {
        margin-top: 0;
        line-height: calc(26/18);
        font-size: 18px;
    }
}
[class*=col-]:first-child .img-slide__content__col {
    margin-top: 0;
}
.img-slide__content__col__hl {
    line-height: 1;
    margin-bottom: 5px;
    font-size: 20px;

    @media screen and (min-width: 1200px) {
        font-size: 30px;
        margin-bottom: 10px;
    }
}
.img-slide__content__bottom {
    margin-top: 25px;
}

.img-slide__img-rounded img{
    border-radius: 84px;
    border: 7px solid white;
}


.img-slide--fixed-size {
    position: relative;
    background: transparent;
    padding: 0;
}
.img-slide--fixed-size .img-slide__content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

/* no overlay */
.img-slide--no-overlay:before {
    content: none;
}

/* overlay colors */
.img-slide--overlay-dark:before {
    background: #000;
    opacity: .3;
}
.img-slide--overlay-light:before {
    background: #fff;
    opacity: .5;
}