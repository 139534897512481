.quotation-block{
    font-size: 20px;
    line-height: 1;
    margin: 0 auto;
    position: relative;

    @media screen and (min-width: 768px) {
        font-size: 30px;
        width: 775px;
    }
    @media screen and (min-width: 1200px) {
        font-size: 40px;
    }
}
.quotation-block__author {
    font-size: 18px;
    line-height: calc(20/22);
    margin-top: 15px;

    @media screen and (min-width: 768px) {
        font-size: 22px;
        margin-top: 25px;
    }
}

/* with divider */

.quotation-block--divider {
    margin: 35px auto 0;
    padding-top: 20px;

    @media screen and (min-width: 768px) {
        margin-top: 55px;
        padding-top: 40px;
    }
}
.quotation-block--divider:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 4px;
    width: 120px;
    background: #fff;
}