.navbar-main {
    font-size: 14px;
    line-height: calc(24 / 14);
    @media screen and (min-width: 768px){
        font-size: 20px;
        line-height: calc(30 / 20);
    }

    height: 55px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    padding: 0;
    z-index:1000;

    @media screen and (min-width: 768px) {
        height: 80px;
        padding-left: 15px;
    }

    @media screen and (min-width: 1400px) {
        padding-left: 65px;
    }
}
.navbar-collapse {
    align-items: center;
}
.navbar-toggleable-md .navbar-collapse {
    display: -ms-flexbox!important;
    display: flex!important;
    width: 100%;
}
.navbar-toggleable-md, .navbar-toggleable-md .navbar-nav {
    flex-direction: row;
}
.navbar-nav > li {

    @media screen and (min-width: 768px) {
        text-align: center;
        position: static;
        padding: 0 12px;
    }

    @media screen and (min-width: 1450px) {
        padding: 0 20px;
    }
}
.navbar-nav > li > a {
    padding: 15px 0 29px;
    position: relative;
    text-transform: uppercase;
    font-family: var(--font-bold-family);

    @media screen and (min-width: 768px) {
        font-size: 14px;
    }
    @media screen and (min-width: 1400px) {
        font-size: 16px;
    }
}

@media screen and (min-width: 768px) {
    .navbar-nav > li > a:before,
    .navbar-right__btn--white:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height:5px;
        background: var(--color-primary);
        transform: scaleX(0);
        transform-origin: center;
        transition: transform 250ms ease-in-out;
    }
    .navbar-nav > li.active > a:before,
    .navbar-nav > li:hover > a:before,
    .navbar-right__btn--white:hover:before,
    .show .navbar-right__btn:before,
    .is-open .navbar-right__btn:before{
        transform: scaleX(1);
    }
}

.navbar-brand {
    position: relative;
    z-index: 1;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
    flex: none;

    @media screen and (min-width: 768px) {
        height: 80px;
        max-width: 90px;
    }
    @media screen and (min-width: 1200px) {
        max-width: none;
    }
    @media screen and (min-width: 1600px) {
        padding-right: 20px;
    }
}

/* navbar right buttons */
.navbar-main .navbar-right {
    margin: 0;
    font-size: 0;
}
.navbar-main .navbar-right > li {
    padding: 0;
    float: left;
}
.navbar-right .navbar-right__btn {
    display: block;
    padding: 17px 14px;
    height: 55px;
    width: 52px;
    overflow: hidden;
    position: relative;

    @media screen and (min-width: 768px) {
        width: auto;
        height: 80px;
        padding: 15px 8px;
        font-size: 14px;
        min-width: 45px;
    }

    @media screen and (min-width: 1520px) {
        padding: 15px 10px;
    }
    @media screen and (min-width: 1600px) {
        padding: 15px 16px;
    }
    @media screen and (min-width: 1650px) {
        padding: 15px 20px;
    }
}
.navbar-right__btn__icon {
    font-size: 20px;

    @media screen and (min-width: 768px) {
        font-size: 24px;
        margin-bottom: 5px;
        position: absolute;
        transform: translateY(-50%) translateX(-50%);
        left: 50%;
        top: 50%;
    }

    @media screen and (min-width: 1200px) {
        position: relative;
        transform: none;
        top: auto;
        left: auto;
    }

    @media screen and (min-width: 1600px) {
        font-size: 30px;
    }
}
.navbar-right__btn__icon__count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-30%) translateX(-50%);
    font-size: 14px;
}

/* navbar-right dropdown */
.navbar-main .dropdown-toggle:after {
    content: none;
}

/* dark nav */
.navbar-main--dark {
    background: var(--color-dark);
    color: #fff;
}

/* transparent nav */
.navbar-main--transparent {
    background: transparent;
    color: #fff;
    box-shadow: none;
}

/* hover menu aim */
.navbar-nav > li:hover > a:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -55px;
    right: -55px;
    height: 25px;
    z-index: 1;
}